import * as React from 'react';
import {
  Button, Col, Form, Modal, Row,
} from 'react-bootstrap';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

function ErrorBoundaryMsg({ error }: FallbackProps) {
  console.error(error);
  return (
    <div className="text-danger">
      <p>Something went wrong.</p>
      {/* <pre>{error.message}</pre> */}
    </div>
  );
}

type StrEditTypes =
  { type: 'str', min?: number, max?: number } |                 // String  (optionally specify length)
  { type: 'choice', choices: string[], defaultValue?: string }; // Selection

type NumEditTypes =
  { type: 'int', min?: number, max?: number, step?: number } |  // Integer (optionally specify range)
  { type: 'dbl', min?: number, max?: number, step?: number };   // Double  (optionally specify range)

export type EditType = NumEditTypes | StrEditTypes;

type EditFormInputProps<ET extends EditType> = {
  edit?: ET,
  initValue: (ET extends StrEditTypes ? string : number) | null,
  onUpdate: (data: (ET extends StrEditTypes ? string : number)) => any,
};

function EditFormInput<ET extends EditType>({ edit, initValue, onUpdate }: EditFormInputProps<ET>) {
  const [value, reactSetValue] = React.useState(initValue);
  const setValue = (v: (ET extends StrEditTypes ? string : number)) => {
    reactSetValue(v);
    onUpdate(v);
  };

  if (edit === undefined) {
    return <Form.Label>{value}</Form.Label>;  // Not editable.
  }
  if (edit.type === 'int' || edit.type === 'dbl') {
    return (
      <Row>
        <Col xs={2}><Form.Label>{value}</Form.Label></Col>
        <Col xs={10}>
          <Form.Range
            defaultValue={initValue ?? edit.min ?? edit.max ?? 0}
            min={edit.min}
            max={edit.max}
            step={edit.step ?? (edit.type === 'int' ? 1 : 0.1)}
            onChange={(e) => { (setValue as (value: number) => any)(parseFloat(e.target.value)); }}
          />
        </Col>
      </Row>
    );
  }

  if (edit.type === 'str') {
    return (
      <Form.Control
        defaultValue={initValue?.toString()}
        onChange={(e) => { (setValue as (value: string) => any)(e.target.value); }}
      />
    );
  }
  if (edit.type === 'choice') {
    return (
      <Form.Select
        defaultValue={initValue ?? edit.defaultValue ?? ''}
        onChange={(e) => { (setValue as (value: string) => any)(e.target.value); }}
      >
        {edit.choices.map((choice) => <option key={choice}>{choice}</option>)}
      </Form.Select>
    );
  }

  throw Error('Unknown edit type:', (edit as any).type);
}

export type ColSpec<RowKey extends string | number, PlainValue extends string | number> = {
  node: React.ReactNode,
  classes?: string,
  cellWidth?: string,
} & (
  { editType?: undefined, getPlainValue?: undefined }
  | {
    /* When specified (and the table is editable),
    * the column wil be editable as specified by this type.
    */
    editType: EditType,
    getPlainValue: (key: RowKey) => PlainValue
  }
);

// interface Columns<
//   RK extends string,
//   ColSpecs extends {
//     [col: string]: ColSpec<RK, string | number>
//   }> {}

function RowEditForm<RK extends string, C extends { [col: string]: ColSpec<RK, string | number> }>(
  {
    columns,
    show,
    rowKey,
    onUpdate,
    onDelete,
    onCancel,
  }:
  {
    columns: C,
    show: boolean,
    rowKey: RK | null,
    onUpdate: (
      rowKey: RK | null,
      data: { [col in keyof C]?: C[col] extends ColSpec<RK, infer Val> ? Val : undefined }
    ) => any,  // Todo: mapping
    onDelete: (rowKey: RK) => any,
    onCancel: () => any
  },
) {
  const [values, setValues] = React.useState({});
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{rowKey !== null ? 'Edit Row' : 'New Row'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.entries(columns).map(([col, { node, editType, getPlainValue }]) => (
          <div key={col}>
            <Form.Label className="pt-3">{node ?? col}</Form.Label>
            <EditFormInput
              edit={editType}
              initValue={(rowKey !== null && getPlainValue) ? getPlainValue(rowKey) : null}
              onUpdate={(data) => setValues({ ...values, [col]: data })}
            />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel}>Cancel</Button>
        { rowKey === null ? null : <Button onClick={() => onDelete(rowKey)} className="bg-danger">Delete Row</Button> }
        <Button
          onClick={() => { onUpdate(rowKey, values); setValues({}); }}
          className="bg-warning"
        >
          Submit Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

type TableProps<RK extends string, C extends { [col: string]: ColSpec<RK, string | number> }> = {
  columns: C;
  data: { [key in RK]: { [col in keyof C]?: React.ReactNode } };
  header?: boolean;
} & (
  { editable?: false,
    onRowNew?: any,    // Note: Value is ignored when editable is false.
    onRowUpdate?: any, // Note: Value is ignored when editable is false.
    onRowDel?: any     // Note: Value is ignored when editable is false.
  } |
  {
    editable: true,
    onRowNew: (
      data: {
        [col in keyof C]?: C[col] extends ColSpec<RK, infer Val> ? Val : undefined
      }
    ) => any,
    onRowUpdate: (
      key: RK,
      data: {
        [col in keyof C]?: C[col] extends ColSpec<RK, infer Val> ? Val : undefined
      }
    ) => any,
    onRowDel: (key: RK) => any
  }
);

// Change data so each cell is a closure?
// Slower, but defers rendering to be within an error component
export default function Table<
  RK extends string,
  C extends { [col: string]: ColSpec<RK, string | number> },
>({
  columns,
  data,
  header = false,
  editable = false,
  onRowNew = undefined,
  onRowUpdate = undefined,
  onRowDel = undefined,
}: TableProps<RK, C>) {
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [editKey, setEditKey] = React.useState<RK | null>(null);

  return (
    <>
      {!editable ? null : (
        <RowEditForm<RK, C>
          columns={columns}
          show={showEditModal}
          rowKey={editKey}
          onCancel={() => { setShowEditModal(false); setEditKey(null); }}
          onDelete={(rk) => { setShowEditModal(false); (onRowDel as any)(rk); setEditKey(null); }}
          onUpdate={(rk, rd) => {
            setShowEditModal(false);
            if (rk === null) {
              (onRowNew as any)(rd);
            } else {
              (onRowUpdate as any)(rk, rd);
            }
            setEditKey(null);
          }}
        />
      )}
      <div className="table-responsive">
        <table className="bg8-list table table-borderless text-purple-dark">
          {/* <colgroup>
            {Object.entries(columns).map(
              ([col, { classes }]) => <col key={col} className={classes} />,
            )}
          </colgroup> */}
          <thead>
            <tr className={header ? 'd-none d-xl-table-row' : 'd-none'}>
              {Object.entries(columns).map(([col, { node }]) => <th scope="col" key={col}>{node}</th>)}
            </tr>
          </thead>
          {Object.entries<TableProps<RK, C>['data'][RK]>(data).map(([key, row]) => (
            <Row as="tbody" key={key} className="bg8-list-item d-xl-table-row align-middle text-center">
              {Object.entries(columns)
                .concat(editable ? [['__edit__', { node: null }]] : [])  // Add a 'column' for the edit row button.
                .map(([col, { node, classes = '', cellWidth = '100' }]) => (
                  <Col as="tr" key={col} xl="auto" className={`px-0 d-table-row d-xl-table-cell ${classes}`}>
                    <th scope="row" className={`${header ? 'd-inline-flex' : 'd-none'} p-0 d-xl-none justify-content-end`}>
                      <ErrorBoundary FallbackComponent={ErrorBoundaryMsg}>
                        {node}
                      </ErrorBoundary>
                    </th>
                    <td className={`d-inline-flex justify-content-center justify-content-xl-start w-${cellWidth}`}>
                      <ErrorBoundary FallbackComponent={ErrorBoundaryMsg}>
                        {col !== '__edit__'
                         ? (row[col])
                         : (
                           <Button
                             className="py-1 px-2 border-0"
                             onClick={() => { setShowEditModal(true); setEditKey(key as RK); }}
                           >
                             <span className="fs-4 bi-pencil-square" />
                           </Button>
                        )}
                      </ErrorBoundary>
                    </td>
                  </Col>
                ))}
            </Row>
          )).concat(
            !editable ? [] : [(
              <Row as="tbody" key="__new_row__">
                <tr>
                  <td>
                    <Button onClick={() => { setShowEditModal(true); setEditKey(null); }}>
                      <span className="fs-4 bi-plus-square" />
                    </Button>
                  </td>
                </tr>
              </Row>
            )],
          )}
        </table>
      </div>
    </>
  );
}
