import * as React from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';

export default function LoginPage(
  { login, loginError }: {
    login: (user: string, pwd: string) => any,
    loginError: null | 'auth' | 'connection' | 'connecting' | 'loggedout'
  },
) {
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();
  // const onSubmit = (data) => console.log(data);

  const [state, setState] = React.useState({ user: '', pwd: '' });

  return (
    <Container fluid className="h-100">
      <Row className="flex-nowrap h-100">
        <Col lg={9} className="d-none d-lg-flex bg-primary h-100">
          <img // eslint-disable-line
            className="img-fluid m-auto h-100"
            alt="FSI Splash"
            src="/pyrolink_login.png"
          />
        </Col>
        <Col xs={12} lg={3} className="d-flex flex-column justify-content-center">
          <h1>Welcome!</h1>
          <p />
          <Form
            className="me-2 p-2"
            onSubmit={(e) => {
              e.preventDefault();
              login(state.user, state.pwd);
              return false;
            }}
          >
            <Form.Group className="mb-3" controlId="user">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                onChange={(e) => {
                  setState({ ...state, user: e.target.value.trim() });
                }}
              />
              {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
            </Form.Group>

            <Form.Group className="mb-3" controlId="pass">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                onChange={(e) => {
                  setState({ ...state, pwd: e.target.value.trim() });
                }}
              />
            </Form.Group>
            <p />
            {/* <NavLink to="/" style={{ textDecoration: 'none' }}> */}
            <Button
              variant="primary"
              type="submit"
              disabled={!Object.values(state).every((v) => v.length)}
            >
              Login
            </Button>
            {/* </NavLink> */}
          </Form>
          {
            loginError === null
            ? null
            : loginError === 'connection'
            ? <Alert variant="danger">Connection error.</Alert>
            : loginError === 'auth'
            ? <Alert variant="danger">Username or password incorrect.</Alert>
            : loginError === 'loggedout'
            ? <Alert variant="info">Logged out.</Alert>
            // : loginError === 'connecting'
            : <Alert variant="info">Logging in.</Alert>
          }
          {/* <form onSubmit={handleSubmit(onSubmit)}>
            <input {...register('firstName', { required: true })} />
            {errors.firstName?.type === 'required' && 'First name is required'}
            <input {...register('lastName', { required: true })} />
            {errors.lastName && 'Last name is required'}
            <input type="submit" />
          </form> */}
        </Col>
      </Row>
    </Container>
  );
}
