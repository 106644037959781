import * as React from 'react';
import {
  Badge, Button, Carousel, Col, Row,
} from 'react-bootstrap';
import {
  useParams,
  NavLink,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { DateTime } from 'luxon';

import {
  Model, Site, Alarm, ServiceStatusInfo,
} from '../model';
import Block from '../components/Block';
import Page from '../components/Page';
import Table, { ColSpec } from '../components/Table';
import { GMap, InfoWindow, Marker } from '../components/Map';
import HsnAlert from '../components/HsnAlert';
import Time from '../components/Time';
import DeviceStatusBadge from '../components/DeviceStatusBadge';
import { CameraButtonClass } from '../utils/CameraUtils';

import { sortByStrValue } from '../util';

function ZoneStatus({ site }: { site: Site }) {
  // TODO: flicker causing bug after leaving page idle for long time?
  // const flicker = useSpring({
  //   loop: true,
  //   to: [{ opacity: 1 }, { opacity: 0.7 }],
  //   from: { opacity: 0.7 },
  // });

  return (
    <Table
      columns={{
        zone: { node: null, classes: 'col-md-4' },
        alarms: { node: null, classes: 'col-sm-6 col-md-4' },
        faults: { node: null, classes: 'col-sm-6 col-md-4' },
      }}
      data={Object.fromEntries(Object.entries(site.zones).map(([zoneId, zone]) => {
        const activeAlarms = Object.values(site.alarms).filter((alarm) => (
          (alarm.site_id === site.id) && (alarm.zone_id === zoneId) && (alarm.status !== 'Over')
        ));

        if (activeAlarms.length > 1) {  // TODO: Use for fuzz testing.
          console.log('Warning: More than one active alarm for a zone!');
        }

        const alarm: Alarm | undefined = activeAlarms[0];

        const zoneDevices = Object.values(site.devices)
          .filter((d) => (d.site_id === site.id) && (d.zone_id === zoneId));

        const faultCount = {
          CRITICAL: 0, WARNING: 0, MILD: 0, UNKNOWN: 0,
        };
        zoneDevices.forEach((dev) => {
          Object.values(dev.faults).forEach((f) => { faultCount[f.severity] += 1; });
          if (dev.last_contact === undefined || dev.last_contact === null) {
            faultCount.CRITICAL += 1;
          } else {
            const hoursSinceLastContact = -(DateTime.fromISO(dev.last_contact, { zone: 'utc' }).diffNow().as('hours'));
            if (hoursSinceLastContact >= 5) {
              faultCount.CRITICAL += 1;
            } else if (hoursSinceLastContact >= 2) {
              faultCount.WARNING += 1;
            }
          }
        });
        const worstFault = (
          faultCount.CRITICAL ? 'danger'
          : faultCount.UNKNOWN ? 'danger'
          : faultCount.WARNING ? 'warning'
          : faultCount.MILD ? 'info'
          : 'success'
        );
        const totalFaults = Object.values(faultCount).reduce((prev, cur) => prev + cur, 0);

        return [
          zoneId,
          {
            zone: <strong title={zone.desc} className="fs-5">{zone.name}</strong>,
            alarms: (
              // <animated.span style={alarm === undefined ? undefined : flicker} className="w-100">
              <Badge
                bg={alarm === undefined ? 'success'
                    : alarm.status === 'Unhandled' ? 'danger' : 'warning'}
                className="w-100 badge-xl"
              >
                {alarm === undefined ? 'No Alarm Active' : (
                  <NavLink className="text-light" to={`/site/${site.id}/alarm/${alarm.id}`}>
                    <strong>{alarm.status === 'Active' ? 'Active Alarm!' : 'Unhandled Alarm!'}</strong>
                  </NavLink>
                )}
              </Badge>
              // </animated.span>
            ),
            faults: (
              <Badge bg={worstFault} className="w-100 badge-xl">
                <NavLink to={{ hash: 'Devices' }} className="text-light">
                  {`${totalFaults} Device Fault(s)`}
                </NavLink>
              </Badge>
            ),
          },
        ];
      }))}
    />
  );
}

function HazardsAndRisks(
  {
    data,
    canEdit,
    onRowNew, onRowUpdate, onRowDel,
  }: {
    data: Site['risks'],
    canEdit: boolean,
    onRowNew: (data: Partial<Site['risks'][string]>) => any,
    onRowUpdate: (key: string, data: Partial<Site['risks'][string]>) => any,
    onRowDel: (key: string) => any
  },
) {
  return (
    <Table<string, {
      type: ColSpec<string, string>,
      location: ColSpec<string, string>,
      details: ColSpec<string, string>,
      link: ColSpec<string, string>,
    }>  // Couldn't get type inference to work here
      header
      editable={canEdit}
      onRowNew={onRowNew}
      onRowUpdate={onRowUpdate}
      onRowDel={onRowDel}
      columns={{
        type: {
          node: 'Type',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'choice', choices: ['Chemical', 'Construction', 'Other'], defaultValue: 'Chemical' },
          getPlainValue: (r) => data[r]?.type ?? 'Chemical',
        },
        location: {
          node: 'Location',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 1, max: 50 },
          getPlainValue: (r) => data[r]?.location ?? '',
        },
        details: {
          node: 'Details',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 1, max: 100 },
          getPlainValue: (r) => data[r]?.details ?? '',
        },
        link: {
          node: 'Link',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 0, max: 200 },
          getPlainValue: (r) => data[r]?.link ?? '',
        },
      }}
      data={data}
    />
  );
}

function MIPTable(
  {
    data,
    canEdit,
    onRowNew, onRowUpdate, onRowDel,
  }: {
    data: Site['mips'],
    canEdit: boolean,
    onRowNew: (data: Partial<Site['mips'][string]>) => any,
    onRowUpdate: (key: string, data: Partial<Site['mips'][string]>) => any,
    onRowDel: (key: string) => any
  },
) {
  return (
    <Table<string, {
      name: ColSpec<string, string>,
      location: ColSpec<string, string>,
      details: ColSpec<string, string>,
      phone: ColSpec<string, string>,
      email: ColSpec<string, string>,
    }>
      header
      editable={canEdit}
      onRowNew={onRowNew}
      onRowUpdate={onRowUpdate}
      onRowDel={onRowDel}
      columns={{
        name: {
          node: 'Name',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 1, max: 50 },
          getPlainValue: (r) => data[r]?.name ?? '',
        },
        location: {
          node: 'Location',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 1, max: 50 },
          getPlainValue: (r) => data[r]?.location ?? '',
        },
        details: {
          node: 'Details',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 1, max: 100 },
          getPlainValue: (r) => data[r]?.details ?? '',
        },
        phone: {
          node: 'Phone',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 0, max: 50 },
          getPlainValue: (r) => data[r]?.phone ?? '',
        },
        email: {
          node: 'Email',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 0, max: 50 },
          getPlainValue: (r) => data[r]?.email ?? '',
        },
      }}
      data={data}
    />
  );
}

function ContactsTable(
  {
    data,
    canEdit,
    onRowNew, onRowUpdate, onRowDel,
  }: {
    data: Site['contacts'],
    canEdit: boolean,
    onRowNew: (data: Partial<Site['contacts'][string]>) => any,
    onRowUpdate: (key: string, data: Partial<Site['contacts'][string]>) => any,
    onRowDel: (key: string) => any
  },
) {
  return (
    <Table<string, {
      name: ColSpec<string, string>,
      phone: ColSpec<string, string>,
      email: ColSpec<string, string>,
      position: ColSpec<string, string>,
    }>
      header
      editable={canEdit}
      onRowNew={onRowNew}
      onRowUpdate={onRowUpdate}
      onRowDel={onRowDel}
      columns={{
        name: {
          node: 'Name',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 1, max: 50 },
          getPlainValue: (r) => data[r]?.name ?? '',
        },
        phone: {
          node: 'Phone',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 0, max: 50 },
          getPlainValue: (r) => data[r]?.phone ?? '',
        },
        email: {
          node: 'Email',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 0, max: 50 },
          getPlainValue: (r) => data[r]?.email ?? '',
        },
        position: {
          node: 'Position',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 1, max: 100 },
          getPlainValue: (r) => data[r]?.position ?? '',
        },
      }}
      data={data}
    />
  );
}

function OccupancyTable(
  {
    areas,
    canEdit,
    onRowNew, onRowUpdate, onRowDel,
  }: {
    areas: Site['areas'],
    canEdit: boolean,
    onRowNew: (data: Partial<Site['areas'][string]>) => any,
    onRowUpdate: (key: string, data: Partial<Site['areas'][string]>) => any,
    onRowDel: (key: string) => any
  },
) {
  return (
    <Table<string, {
      id: ColSpec<string, string>,
      occupancy: ColSpec<string, number>,
      business_hours: ColSpec<string, string>,
      after_hours: ColSpec<string, string>
    }> // Couldn't get type inference to work here
      header
      editable={canEdit}
      onRowNew={onRowNew}
      onRowUpdate={onRowUpdate}
      onRowDel={onRowDel}
      columns={{
        id: {
          node: <span className="d-none">Area</span>,
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 1, max: 25 },
          getPlainValue: (r) => areas[r].id,
        },
        occupancy: {
          node: 'Number in Area',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'int', min: 0, max: 1000 },
          getPlainValue: (r) => areas[r]?.occupancy ?? 0,
        },
        business_hours: {
          node: 'Business Hours',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 1, max: 100 },
          getPlainValue: (r) => areas[r]?.business_hours ?? '',
        },
        after_hours: {
          node: 'After Hours ',
          classes: 'text-xl-start',
          cellWidth: 'auto',
          editType: { type: 'str', min: 1, max: 100 },
          getPlainValue: (r) => areas[r]?.after_hours ?? '',
        },
      }}
      data={areas}
    />
  );
}

// function ContactList({ contacts }: { contacts: Contact[] }) {
//   return (
//     <ul className="mb-0">
//       {
//         contacts.map((contact) => (
//           <li key={contact.id}>
//             <address className="mb-0">
//               <Row as="dl" className="mb-0">
//                 <Col xl={3}>
//                   <Row>
//                     <Col as="dt" xs="auto" hidden>Name</Col>
//                     <Col as="dd">{contact.name}</Col>
//                   </Row>
//                 </Col>
//                 <Col xl={3}>
//                   <Row>
//                     <Col as="dt" xs="auto">Ph:</Col>
//                     <Col as="dd"><a href={`tel:${contact.phone}`}>{contact.phone}</a></Col>
//                   </Row>
//                 </Col>
//                 <Col xl={6}>
//                   <Row>
//                     <Col as="dt" xs="auto">Email:</Col>
//                     <Col as="dd"><a href={`mailto:${contact.email}`}>{contact.email}</a></Col>
//                   </Row>
//                 </Col>
//               </Row>
//             </address>
//           </li>
//         ))
//       }
//     </ul>
//   );
// }

function DeviceList({ site }: { site: Site }) {
  return (
    <Table
      header
      editable={false}
      columns={{
        name: {
          node: <span className="d-none">Name</span>,
          classes: 'fs-4 text-nowrap text-xl-start col-12 col-sm-6',
          cellWidth: 'auto',
        },
        zone: {
          node: <span className="d-none d-xl-inline">Zone</span>,
          classes: 'text-nowrap text-xl-start col-12 col-sm-6',
          cellWidth: 'auto',
        },
        type: {
          node: 'Type',
          classes: 'text-nowrap text-xl-start col-12 col-sm-6 col-md-3',
          cellWidth: 'auto',
        },
        temp: {
          node: 'Temp',
          classes: 'text-nowrap text-xl-start col-12 col-sm-6 col-md-3',
          cellWidth: 'auto',
        },
        /*
        battery: {
          node: 'Battery',
          classes: 'text-nowrap text-xl-start col-12 col-sm-6 col-md-3',
          cellWidth: 'auto',
        },
        */
        lastContact: {
          node: 'Last Contact',
          classes: 'col-12 col-md-6 text-xl-start text-nowrap',
          cellWidth: 'auto',
        },
        status: {
          node: <span className="d-none d-xl-inline">Status</span>,
          classes: 'text-nowrap col-12 col-sm-6',
        },
        warning: {
          node: <span className="d-none d-xl-inline">Warnings</span>,
          classes: 'text-nowrap col-12 col-sm-6',
        },
        link: { node: null, classes: 'col-12' },
      }}
      data={Object.fromEntries(
        sortByStrValue('name', Object.values(site.devices) /* .filter((d) => d.last_contact !== null) */).map((device) => {
          const faultCount = Object.values(device.faults).reduce(
            (count, fault) => ({ ...count, [fault.severity]: count[fault.severity] + 1 }),
            { CRITICAL: 0, WARNING: 0, MILD: 0, UNKNOWN: 0 },  // eslint-disable-line
          );

          // Pick the badge color variant based on the severity of the worst fault.
          const worstFault = (
            faultCount.CRITICAL ? 'danger'
            : faultCount.WARNING ? 'warning'
            : faultCount.MILD ? 'info'
            : 'success'
          );
          const totalFaults = Object.values(faultCount).reduce((prev, cur) => prev + cur, 0);

          return [
            device.id,
            {
              status: <DeviceStatusBadge device={device} prefix="" />,
              warning: <Badge bg={worstFault} className="badge-xl w-100">{`${totalFaults} Issue(s)`}</Badge>,
              name: <strong>{device.name}</strong>,
              zone: <strong>{site.zones[device.zone_id].name}</strong>,
              type: device.type,
              temp: device.temp === null ? null : `${device.temp} °C`,
              // battery: (device?.battery_voltage && (device.battery_voltage > 0.001)) ?
              //  `${device.battery_voltage} V` : 'Unknown',
              lastContact: (device.last_contact ? <Time relative date={device.last_contact} /> : 'Unknown.'),
              link: (
                <NavLink to={`/site/${site.id}/device/${device.id}`} className="d-inline-flex w-100 text-nowrap">
                  <Button className="btn-icon d-inline-flex text-nowrap w-100">
                    View
                    <i className="bi-chevron-right" />
                  </Button>
                </NavLink>
              ),
            },
          ];
        }),
      )}
    />
  );
}

function CameraList({ site }: { site: Site }) {
  return (
    <Table
      header
      editable={false}
      columns={{
        name: {
          node: <span className="d-none">Name</span>,
          classes: 'fs-4 text-nowrap text-xl-start col-12 col-sm-6',
          cellWidth: 'auto',
        },
        location: {
          node: <span className="d-none d-xl-inline">Location</span>,
          classes: 'text-nowrap text-xl-start col-12 col-sm-6',
          cellWidth: 'auto',
        },
        link: { node: null, classes: 'col-12' },
      }}
      data={Object.fromEntries(
        sortByStrValue('id', Object.values(site.cameras)).map((camera) => (
          [
            camera.id,
            {
              name: <strong>{camera.name}</strong>,
              location: <strong>{camera.location}</strong>,
              link: (
                (camera.type === 'tvr')
                || (
                  <NavLink to={`/site/${site.id}/camera/${camera.id}`} className="d-inline-flex w-100 text-nowrap">
                    <Button className={`${CameraButtonClass(camera.displayStatus)} btn-icon d-inline-flex text-nowrap w-100`}>
                      View
                      <i className="bi-chevron-right" />
                    </Button>
                  </NavLink>
                )
              ),
            },
          ])),
      )}
    />
  );
}

function SiteInfo({ site }: { site: Site }) {
  // const owners = site.owners.map((id) => contacts[id]);
  // const security = site.security.map((id) => contacts[id]);
  return (
    <dl>
      <dt>Address</dt>
      <dd><address>{site.info?.address}</address></dd>
      <Row>
        <Col lg={8}>
          <dt>
            Building Cladding&nbsp;&nbsp;
            <a href="https://www.vba.vic.gov.au/cladding" target="_blank" rel="noreferrer">
              <span className="bi-info-circle" />
            </a>
          </dt>
          <dd>
            {site.info?.cladding}
          </dd>
        </Col>
        <Col lg={4}>
          <dt>Number of Levels</dt>
          <dd>{site.info?.levels}</dd>
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <dt>
            Building Classes&nbsp;&nbsp;
            <a
              href="https://www.vba.vic.gov.au/building/regulatory-framework/building-classes"
              target="_blank"
              rel="noreferrer"
            >
              <span className="bi-info-circle" />
            </a>
          </dt>
          <dd>
            {site.info?.classes}
          </dd>
        </Col>
        <Col lg={4}>
          <dt>Exposure</dt>
          <dd>{site.info?.exposure}</dd>
        </Col>
      </Row>
      <dt>Site Owner(s)</dt>
      <dd>{site.info?.owner}</dd>
      <dt>Security</dt>
      <dd>{site.info?.security}</dd>
    </dl>
  );
}

function SiteGMap(
  { coords, apiKey, siteLabel }: {
    coords: { lat: number, lng: number },
    apiKey: string | null,
    siteLabel: string
  },
) {
  const infoWindow = (
    <InfoWindow
      autoshow
      marker={(
        <Marker
          title={siteLabel}
          position={coords}
        />
      )}
    >
      {siteLabel}
    </InfoWindow>
  );

  return (
    <div
      className="bg8-embed-container rounded-3 w-100 h-100"
      style={{ position: 'relative', minHeight: '350px' }}
    >
      <GMap apiKey={apiKey} center={coords} zoom={13} disableDefaultUI fullscreenControl mapId="1">
        {/*
          TODO: Google script isn't loaded immediately,
          which means can't use the enums in props.
           animation={2.0} />
          */}
        {/* <Marker position={coords} /> */}
        {infoWindow}
      </GMap>
    </div>
  );
}

function ServiceStatusListItem(
  { service_name, status_info }: { service_name: string, status_info: ServiceStatusInfo },
) {
  const serviceNameString: string = `${service_name}`;
  const severityString: string = status_info?.status_severity ? `:  ${status_info?.status_severity}` : '';
  const statusString: string = status_info?.status ? `:  ${status_info?.status}` : '';
  const descriptionString: string = status_info?.description ? `:  ${status_info?.description}` : '';
  return (
    <li key={service_name}>
      <strong>{serviceNameString}</strong>
      {severityString}
      {statusString}
      {descriptionString}
    </li>
  );
}

export default function SitePage(
  {
    appState,
    gMapsApiKey,
    userName,
    isSingleSiteUser,
    onRowNew,
    onRowUpdate,
    onRowDel,
  }: {
    appState: Model,
    gMapsApiKey: string | null,
    userName: string | null,
    isSingleSiteUser: boolean,
    onRowNew: (site: Site['id'], table: string, data: object) => any,
    onRowUpdate: (site: Site['id'], table: string, key: string, data: object) => any,
    onRowDel: (site: Site['id'], table: string, key: string) => any },
) {
  const { siteId } = useParams();

  // const flicker = useSpring({
  //   loop: true,
  //   to: [{ opacity: 1 }, { opacity: 0 }],
  //   from: { opacity: 0 },
  // });

  // Refs for focusing
  const refs = {
    Zones: React.useRef<HTMLDivElement | null>(null),
    Info: React.useRef<HTMLDivElement | null>(null),
    Layout: React.useRef<HTMLDivElement | null>(null),
    Risks: React.useRef<HTMLDivElement | null>(null),
    Contacts: React.useRef<HTMLDivElement | null>(null),
    Map: React.useRef<HTMLDivElement | null>(null),
    Mobility: React.useRef<HTMLDivElement | null>(null),
    Occupancy: React.useRef<HTMLDivElement | null>(null),
    Cameras: React.useRef<HTMLDivElement | null>(null),
    Devices: React.useRef<HTMLDivElement | null>(null),
  };
  // type RefsTypeAlias = Map<string, React.MutableRefObject<HTMLDivElement | null>>;

  const { hash } = useLocation();
  React.useEffect(() => {
    const strippedHash = hash.substring(1) as keyof typeof refs;
    if (strippedHash in refs) {
      const { current } = refs[strippedHash];
      if (current) {
        // sleep(1000).then(() => { current.focus(); });
        current.scrollIntoView({ behavior: 'smooth' });
        current.focus();
        console.log('Focused onto:', hash);
        return;
      }
    }
    console.log('Not able to focus onto:', hash);
  }, [hash, ...Object.values(refs)]);  // eslint-disable-line

  if ((siteId === undefined) || !(siteId in appState.sites)) {
    return <Navigate to="/404" />;
  }

  const site = appState.sites[siteId];
  const servicesWithFault = Object.entries(site.site.services).filter(([, status_info]) => status_info.status_severity === 'Fault');
  const servicesWithCriticalFault = Object.entries(site.site.services).filter(([, status_info]) => status_info.status_severity === 'Critical Fault');
  const allRunning = ((servicesWithFault.length + servicesWithCriticalFault.length) === 0);
  const haveFault = (servicesWithFault.length !== 0);
  const haveCriticalFault = (servicesWithCriticalFault.length !== 0);
  const siteServiceStatus = (
    <Badge
      bg={haveCriticalFault ? 'danger' : (allRunning ? 'success' : 'warning')}
      className="badge-xl d-flex me-4 pe-4 ps-3 mt-2 mb-3 mb-md-5 mx-4"
    >
      {/* <animated.div style={flicker} className="pe-2"> */}
      <span className={allRunning ? 'bi-play-circle-fill pe-2' : 'bi-exclamation-octagon-fill pe-2'} />
      {/* </animated.div> */}
      {haveCriticalFault ? 'Site Error!' : (allRunning ? 'Site Online' : 'Site Warning!')}
    </Badge>
  ); // TODO

  const handleLayoutClick = () => {
    const layoutImage = refs.Layout.current;
    if (layoutImage) {
      if (layoutImage.requestFullscreen) {
        layoutImage.requestFullscreen();
      }
    }
  };

  const pageHeading = isSingleSiteUser ? `Welcome ${userName}` : `Sites / ${site.site.name}`;

  return (
    <Page heading={pageHeading} topElem={siteServiceStatus}>
      {allRunning ? null : (
        <Col xs={12} className="d-grid">
          {!haveCriticalFault ? null : (
            <HsnAlert header="Service Critical Fault!" theme={{ variant: 'CRITICAL' }}>
              The following services for this site have critical faults:
              <ul>
                {servicesWithCriticalFault.map(([service_name, status_info]) => (
                  ServiceStatusListItem({ service_name, status_info })))}
              </ul>
            </HsnAlert>
          )}
          {!haveFault ? null : (
            <HsnAlert header="Service Fault!" theme={{ variant: 'WARNING' }}>
              The following services for this site have non-critical faults:
              <ul>
                {servicesWithFault.map(([service_name, status_info]) => (
                  ServiceStatusListItem({ service_name, status_info })))}
              </ul>
            </HsnAlert>
          )}
        </Col>
      )}
      <Col xs={12} xxl={6} className="d-grid">
        <Block heading="Status" id="Zones" _ref={refs.Zones}>
          <ZoneStatus site={site.site} />
        </Block>
      </Col>
      <Col xs={12} xxl={6} className="d-grid">
        <Block heading={null} id="Info" _ref={refs.Info}>
          <SiteInfo site={site.site} />
        </Block>
      </Col>
      <Col xs={12} xxl={6} className="d-grid">
        <Block id="Layout" _ref={refs.Layout} heading={null} padding={0} bg="">
          <Carousel variant="dark" interval={null}>
            {site.site.layouts.map((layout) => (
              <Carousel.Item key={layout.url}>
                <img // eslint-disable-line
                  className="img-fluid mb-5 py-5 px-3"
                  alt={layout.name}
                  src={layout.url}
                  onClick={handleLayoutClick}
                />
                <Carousel.Caption><h5>{layout.name}</h5></Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Block>
      </Col>
      <Col xs={12} xxl={6} className="d-grid">
        <Block id="Map" _ref={refs.Map} heading={null} padding={0}>
          {
            site.site.info?.coords ? (
              <SiteGMap
                coords={site.site.info?.coords}
                apiKey={gMapsApiKey}
                siteLabel={site.site.name}
              />
            ) : 'No coordinate data'
          }
        </Block>
      </Col>
      <Col xs={12} xxl={6} className="d-grid">
        <Block id="Contacts" _ref={refs.Contacts} heading="Contacts">
          <ContactsTable
            data={site.site.contacts}
            canEdit={site.access === 'administer'}
            onRowNew={(data) => onRowNew(siteId, 'Contact', data)}
            onRowUpdate={(key, data) => onRowUpdate(siteId, 'Contact', key, data)}
            onRowDel={(key) => onRowDel(siteId, 'Contact', key)}
          />
        </Block>
      </Col>
      <Col xs={12} xxl={6} className="d-grid">
        <Block id="Occupancy" _ref={refs.Occupancy} heading="Occupancy">
          <OccupancyTable
            areas={site.site.areas}
            canEdit={site.access === 'administer'}
            onRowNew={(data) => onRowNew(siteId, 'Area', data)}
            onRowUpdate={(key, data) => onRowUpdate(siteId, 'Area', key, data)}
            onRowDel={(key) => onRowDel(siteId, 'Area', key)}
          />
        </Block>
      </Col>
      <Col xs={12} className="d-grid">
        <Block id="Risks" _ref={refs.Risks} heading="Risks / Hazards">
          <HazardsAndRisks
            data={site.site.risks}
            canEdit={site.access === 'administer'}
            onRowNew={(data) => onRowNew(siteId, 'Risk', data)}
            onRowUpdate={(key, data) => onRowUpdate(siteId, 'Risk', key, data)}
            onRowDel={(key) => onRowDel(siteId, 'Risk', key)}
          />
        </Block>
      </Col>
      <Col xs={12} className="d-grid">
        <Block id="Mobility" _ref={refs.Mobility} heading="Registered Mobility Impaired Persons">
          <MIPTable
            data={site.site.mips}
            canEdit={site.access === 'administer'}
            onRowNew={(data) => onRowNew(siteId, 'MIP', data)}
            onRowUpdate={(key, data) => onRowUpdate(siteId, 'MIP', key, data)}
            onRowDel={(key) => onRowDel(siteId, 'MIP', key)}
          />
        </Block>
      </Col>
      <Col xs={12} className="d-grid">
        <Block id="Cameras" _ref={refs.Cameras} heading="Cameras">
          <CameraList site={site.site} />
        </Block>
      </Col>
      <Col xs={12} className="d-grid">
        <Block id="Devices" _ref={refs.Devices} heading="Devices" headingMb={0}>
          <DeviceList site={site.site} />
        </Block>
      </Col>
      <Row as="small" className="text-center text-purple-light">
        <Col xs={12} lg={6}>
          {/* Sync Time:&ensp; */}
          {/* <Time date={site.synced} seconds /> */}
        </Col>
        <Col xs={12} lg={6}>
          {/* API-Version:&ensp; */}
          {/* RX.X.X-alpha */}
        </Col>
      </Row>
    </Page>
  );
}
