import * as React from 'react';
import {
  Button, /* Carousel, */ Col, Row,
} from 'react-bootstrap';

import { Navigate, NavLink, useParams } from 'react-router-dom';
import Block from '../components/Block';
import HsnAlert from '../components/HsnAlert';
import Page from '../components/Page';
import Table from '../components/Table';
import Time from '../components/Time';
import DeviceStatusBadge from '../components/DeviceStatusBadge';
import { CameraButtonClass } from '../utils/CameraUtils';
import { canOperateSite } from '../utils/AccessUtils';

import { sortByStrValue } from '../util';

import {
  Model,
  Device,
  Site,
  Camera,
} from '../model';

function DeviceCameraList({ site, cameras }: { site: Site, cameras: Camera[] }) {
  if ((cameras === undefined) || (cameras.length === 0)) {
    return null;
  }
  return (
    <Table
      header
      editable={false}
      columns={{
        name: {
          node: <span className="d-none">Name</span>,
          classes: 'fs-4 text-nowrap text-xl-start col-12 col-sm-6',
          cellWidth: 'auto',
        },
        location: {
          node: <span className="d-none d-xl-inline">Location</span>,
          classes: 'text-nowrap text-xl-start col-12 col-sm-6',
          cellWidth: 'auto',
        },
        link: { node: null, classes: 'col-12' },
      }}
      data={Object.fromEntries(
        sortByStrValue('id', Object.values(cameras)).map((camera) => (
          [
            camera.id,
            {
              name: <strong>{camera.name}</strong>,
              location: <strong>{camera.location}</strong>,
              link: (
                (camera.type === 'tvr')
                || (
                  <NavLink to={`/site/${site.id}/camera/${camera.id}`} className="d-inline-flex w-100 text-nowrap">
                    <Button className={`${CameraButtonClass(camera.displayStatus)} btn-icon d-inline-flex text-nowrap w-100`}>
                      View
                      <i className="bi-chevron-right" />
                    </Button>
                  </NavLink>
                )
              ),
            },
          ])),
      )}
    />
  );
}

export default function DevicePage(
  { appState, onDeviceMute, onReset }: {
    appState: Model,
    onDeviceMute: (siteId: string, devId: Device['id'], mute: boolean) => any,
    onReset: (siteId: string, devId: Device['id']) => any,
  },
) {
  const { siteId, devId } = useParams();
  if (siteId === undefined || !(siteId in appState.sites)) {
    return <Navigate to="/404" />;
  }
  const site = appState.sites[siteId];

  if (devId === undefined || !(devId in site.site.devices)) {
    return <Navigate to="/404" />;
  }
  const device = site.site.devices[devId];

  const cameras = Object.values(site?.site.cameras).filter((cam) => cam.dev_ids.includes(devId));

  const muted = Object.values(device.faults).some((fault) => fault.type === 'Muted');
  const badge = (
    <div className="me-4 mt-2 mb-3 mb-md-5 mx-4">
      <DeviceStatusBadge device={device} />
    </div>
  );

  return (
    <Page heading={`Device / ${device.name}`} topElem={badge}>
      <NavLink to={`/site/${site.site.id}#Devices`} className="d-inline-flex text-nowrap p-5 pt-0">
        <Button className="btn-icon d-inline-flex text-nowrap">
          <i className="bi-chevron-left p-1" />
          Back to site
        </Button>
      </NavLink>
      <Col as="section" xs={9} className={Object.values(device.faults).length ? 'd-grid mb-2' : 'd-none'} aria-label="Faults">
        {sortByStrValue('raised_at', Object.values(device.faults)).map((fault) => (
          <HsnAlert
            key={fault.type}
            header={fault.type}
            time={fault.raised_at}
            theme={{ variant: fault.severity }}
          >
            {fault.desc}
          </HsnAlert>
        ))}
      </Col>
      <Col xs={9} xl={4} className="d-grid">
        <Block heading="Info">
          <dl>
            <dt>EUI</dt>
            <dd>{device.id}</dd>
            <dt>Type</dt>
            <dd>{device.type}</dd>
            <dt>Site</dt>
            <dd><NavLink to={`/site/${siteId}`}>{site.site.name}</NavLink></dd>
            <dt>Zone</dt>
            <dd>{site.site.zones[device.zone_id].name}</dd>
            <dt>Last Heartbeat</dt>
            <dd>{device.last_contact ? <Time date={device.last_contact} relative /> : 'Unknown.'}</dd>
            {/* <dt>Powered On</dt>
            <dd>{device.on_since ? <Time date={device.on_since} /> : 'Unkown.'}</dd> */}
            <dt>Temp</dt>
            <dd>{`${device.temp} °C`}</dd>
            {/*
            <dt>Battery Voltage</dt>
            <dd>{(device?.battery_voltage && (device.battery_voltage > 0.001)) ?
               `${device.battery_voltage} V` : 'Unknown'}</dd>
            */}
            <dt>Location</dt>
            <dd>{device.location}</dd>
          </dl>
        </Block>
      </Col>

      {canOperateSite(site.access) && (
        <Col xs={9} xl={2} className="d-grid">
          <Block heading="Actions">
            <div className="d-flex text-center">
              <Row>
                <Col xs={9} className="py-2">
                  <Button className="d-flex w-100" onClick={() => { onDeviceMute(siteId, devId, !muted); }}>
                    { muted ? 'De-Isolate' : 'Isolate' }
                  </Button>
                </Col>
                <Col xs={9} className="py-2">
                  <Button className="d-flex w-100" onClick={() => { onReset(siteId, devId); }}>
                    Reset
                  </Button>
                </Col>
                {/* <Col xs={9} className="py-2">
                  <Button className="d-flex w-100">Change Profile</Button>
                </Col> */}
              </Row>
            </div>
          </Block>
        </Col>
      )}
      {/*
      <Col xs={9} xl={5} className="d-grid">
        <Block heading={null} padding={0} bg="bg-light">
          <Carousel variant="dark" className="d-flex h-100">
            {[
              { name: 'Install Photo 1', src: 'https://plchldr.co/i/572x500?bg=676767&text=Photo 1' },
              { name: 'Install Photo 2', src: 'https://plchldr.co/i/500x700?bg=706070&text=Photo 2' },
            ].map((layout) => (
              <Carousel.Item key={layout.src} className="h-100">
                <img
                  alt={layout.name}
                  src={layout.src}
                  className="rounded"
                  style={{
                    width: '100%',
                    height: '33em',
                    maxWidth: '100%',
                    objectFit: 'contain',
                    objectPosition: '50% 50%',
                  }}
                />
                <Carousel.Caption><h5 className="text-light">{layout.name}</h5></Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Block>
      </Col>
      */}

      <Col xs={9} xl={6} className="d-grid">
        <Block id="Cameras" heading="Associated Cameras">
          <DeviceCameraList site={site.site} cameras={cameras} />
        </Block>
      </Col>

      {/* <Col xs={9}>
        <Block heading="Event history">
          <ul>
            <li>Prior alarms</li>
            <li>Network outages</li>
            <li>Device warnings</li>
            <li>Profile changes</li>
            <li>Times muted / isolated</li>
          </ul>
        </Block>
      </Col> */}
    </Page>
  );
}
