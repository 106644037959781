import * as React from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

type MapProps = React.PropsWithChildren<google.maps.MapOptions> & {
  // apiKey: string,
  mapId: string,
  bounds?: { lat: number, lng: number }[],
  // controls?: { position: google.maps.ControlPosition | number, elem: HTMLElement }
};

const mapCache: { [mapId: string]: google.maps.Map } = {};

// https://developers.google.com/maps/documentation/javascript/react-map#add-map
function MapComponent(props: MapProps) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  // const [map, setMap] = React.useState<google.maps.Map>();

  const {
    children, bounds, mapId, ...opts  // controls
  } = props;

  React.useEffect(() => {
    if (!(mapId in mapCache)) {
      console.log('Creating new map:', mapId);
      const mapDiv = document.createElement('div');
      mapDiv.id = mapId;
      mapDiv.style.height = '100%'; // = { height: '100%', width: '100%', position: 'absolute' };
      mapDiv.style.width = '100%';
      mapDiv.style.position = 'absolute';
      mapCache[mapId] = new google.maps.Map(mapDiv);
    } else {
      console.log('Using cached map:', mapId);
    }

    // setMap(mapCache[mapId]);
    const parentDiv = ref.current;
    parentDiv?.appendChild(mapCache[mapId].getDiv());
    return () => {
      try {
        parentDiv?.removeChild(mapCache[mapId].getDiv());
      } catch (error) {
        console.log('error removing child ', error);
      }
    };
  }, [mapId]);

  React.useEffect(() => {
    const mapElem = document.getElementById(mapId);
    if (ref.current && mapElem) {
      ref.current.append(mapElem);
    }
  }, [ref, mapId]);

  // React.useEffect(() => {
  //   console.log('New map load request!');
  //   if (ref.current) {
  //     // This issues a new request, so we don't want to do it more than necessary
  //   }
  // }, []);

  // Set the opts
  React.useEffect(() => {
    const map = mapCache[mapId];
    if (map && opts) {
      console.log('Updating opts:', mapId, opts);
      map.setOptions(opts);
    }
  }, [mapId, JSON.stringify(opts)]);  // eslint-disable-line react-hooks/exhaustive-deps
  // Need to stringify for opts for comparison to work,
  // and don't want to rerun if maxZoom option is changed.

  // // Add the controls
  // React.useEffect(() => {
  //   if (map && controls) {
  //     const newSize = map.controls[controls.position].push(controls.elem);
  //     return () => {
  //       map.controls[controls.position].removeAt(newSize - 1);
  //     };
  //   }
  // });

  // Fit the view to the bounds.
  React.useEffect(() => {
    const map = mapCache[mapId];
    if (map && bounds) {
      console.log('new bounds:', mapId, bounds);
      const gmapBounds = new window.google.maps.LatLngBounds();
      bounds.forEach((b) => gmapBounds.extend(b));
      map.setOptions({ maxZoom: 12 });  // Ensure we don't zoom in too far on single points
      map.fitBounds(gmapBounds);
      window.google.maps.event.addListenerOnce(map, 'idle', () => {
        map.setOptions({ maxZoom: opts.maxZoom });
      });
    }
  }, [mapId, JSON.stringify(bounds)]);  // eslint-disable-line react-hooks/exhaustive-deps
  // Need to stringify bounds for compariuson to work,
  // and don't want to rerun if maxZoom option is changed.

  return (
    <>
      <div ref={ref} style={{ height: '100%', width: '100%', position: 'absolute' }} />
      {
        React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { map: mapCache[mapId] } as any);
          }
          return null;
        })
      }
    </>
  );
}

// Straight from https://developers.google.com/maps/documentation/javascript/react-map#marker-component
type MarkerProps = React.PropsWithChildren<google.maps.MarkerOptions> & { onClick?: () => any };
export function Marker(props: MarkerProps) {
  const {
    onClick, children, map, ...options
  } = props;
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      if (map) {
        marker.setMap(map);
      }
    }
  }, [marker, JSON.stringify(options), map]);  // eslint-disable-line react-hooks/exhaustive-deps
  // We need to stringify because for objects React uses Object.is, not an equality check ===
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is#description

  React.useEffect(() => {
    const listener = onClick ? marker?.addListener('click', onClick) : undefined;
    return () => {
      if (listener) {
        google.maps.event.removeListener(listener);
      }
    };
  }, [marker, onClick]);

  return null;
}

type InfoWindowProps = React.PropsWithChildren<
google.maps.InfoWindowOptions
& {
  map?: google.maps.Map,
  marker: React.ReactComponentElement<typeof Marker>,
  autoshow?: boolean
}>;
export function InfoWindow(props: InfoWindowProps) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>();
  const {
    children, map, marker, autoshow, ...options
  } = props;
  // Whether the info window has already been auto-opened or not.
  const haveAutoOpened = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!infoWindow) {
      setInfoWindow(new google.maps.InfoWindow({
        disableAutoPan: true,
      }));
    }

    // remove info window from map on unmount
    return () => {
      if (infoWindow) {
        infoWindow.close();
        infoWindow.setContent(null);
      }
    };
  }, [infoWindow]);

  // Update options when they change
  React.useEffect(() => {
    if (infoWindow) {
      infoWindow.setOptions({ position: marker.props.position, ...options });
      if (autoshow && map && !haveAutoOpened.current) {
        haveAutoOpened.current = true;
        infoWindow.setContent(ref.current);
        infoWindow.open({ map });
      }
    }
  }, [infoWindow, marker, options, map, autoshow]);

  const onMarkerClick = () => {
    infoWindow?.setContent(ref.current);
    infoWindow?.open({ map });
  };

  return (
    <>
      {React.cloneElement(marker, { map, onClick: onMarkerClick })}
      <div ref={ref}>{children}</div>
    </>
  );
}

export function GMap(props: MapProps & { apiKey: string | null }) {
  // This is just a *client side* Google Maps API key, (it's a key, but it's not a secret).
  // Google allows you to restrict the API Keys so they can only be used
  // by certain referrers, and for certain features.
  // Because the key is not a secret it's important the restrictions on the key
  // are as tight as possible. And because referrers can be spoofed etc.
  // See: https://cloud.google.com/blog/products/maps-platform/google-maps-platform-best-practices-restricting-api-keys
  // Also: https://developers.google.com/maps/faq#usage-limits

  const { children, apiKey, ...opts } = props;

  const renderStatus = (status: Status) => {
    if (status === Status.FAILURE) {
      return <p>Failed to load map</p>;
    }
    return <p>Loading map...</p>;
  };

  return (
    <Wrapper apiKey={apiKey || ''} render={renderStatus}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <MapComponent {...opts}>
        {children}
      </MapComponent>
    </Wrapper>
  );
}
