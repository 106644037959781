// Pick the attributes of an object with a string value.
type StrAttributes<T> = Pick<T, { [K in keyof T]-?: T[K] extends string ? K : never }[keyof T]>;

// Test:
// attr='name' or attr='site_id' give no type error. Other attrs do give a type error.
// sortByStrValue('health', [{ name: 'Test', site_id: 'SITE-1', health: 25.0 }]);
export function sortByStrValue<T>( // eslint-disable-line import/prefer-default-export
  attr: keyof StrAttributes<T>,
  array: T[],
  reverse: boolean = false,
) {
  return array.sort((a, b) => {
    const nameA = (a[attr] as unknown as string).toUpperCase();
    const nameB = (b[attr] as unknown as string).toUpperCase();
    if (nameA < nameB)  {
      return reverse ? 1 : -1;
    }
    if (nameA > nameB) {
      return reverse ? -1 : 1;
    }
    return 0;
  });
}
