import * as React from 'react';

interface Props {
  timeSecs: number,
  callback: () => any,
}

/**
 * A class that calls a function at a specified interval.
 * Unlike the underlying interval used, it only calls while the object
 * is displayed. This allows it to be used, for instance, to send
 * keep-alive requests for a camera while a user is viewing a camera.
 */
export default class TimedCaller extends React.Component<Props> {
  private interval: number = 0;

  componentDidMount() {
    const { timeSecs, callback } = this.props;
    if (callback) {
      callback();
    }
    this.interval = window.setInterval(callback, timeSecs * 1000);
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  render() {
    return (
      <div />
    );
  }
}
