import * as React from 'react';
import { Badge, Button, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';

import Block from '../components/Block';
import Page from '../components/Page';
import Table from '../components/Table';
import { Model, SiteInfo } from '../model';
import { GMap, InfoWindow, Marker } from '../components/Map';

function SiteMapLegend({ _ref }: { _ref?: React.LegacyRef<HTMLDivElement> }) {
  const icons = {
    'Unhandled Alarm!': 'icons/pins/unhandled.png',
    // 'Active Fire!': 'icons/pins/fire.png',
    'Site Offline': 'icons/pins/offline.png',
    'Site w/ Faults': 'icons/pins/fault.png',
    'Site Ok': 'icons/pins/ok.png',
  };

  return (
    <div ref={_ref}>
      <details className="site-map-legend rounded">
        <summary><h3 className="d-inline-flex">Legend</h3></summary>
        <ListGroup variant="flush">
          {Object.entries(icons).map(([name, icon]) => (
            <ListGroup.Item key={name} className="px-1 bg-transparent">
              <img src={icon} alt={`${name} icon`} />
              &nbsp;
              <h5 className="d-inline">{name}</h5>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </details>
    </div>
  );
}

export default function SitesPage(
  { appState, gMapsApiKey }: { appState: Model, gMapsApiKey: string | null },
) {
  const mapLegend = React.useRef<HTMLDivElement | null>(null);

  const sydneyCoords = {
    // lat: -33.865,
    // lng: 151.210,
    lat: -33.78301,
    lng: 151.11932,
  };  // Less relevant now that map refits to contain markers.

  return (
    // https://developers.google.com/maps/documentation/javascript/adding-a-legend
    <Page heading="Sites">
      <Block heading={null} padding={0}>
        <div
          className="bg8-embed-container rounded-3 w-100"
          style={{ position: 'relative', minHeight: '500px' }}
        >
          <GMap
            center={sydneyCoords}
            zoom={4}
            mapId="1"
            apiKey={gMapsApiKey}
            disableDefaultUI
            fullscreenControl
            bounds={
              Object.values(appState.sites)
                .map((s) => s.site.info)
                .filter((si) => si !== undefined)
                .map((si) => (si as SiteInfo).coords)
            }
            // controls={mapLegend.current ? { position: 1.0, elem: mapLegend.current } : undefined}
          >
            <SiteMapLegend _ref={mapLegend} />
            {Object.values(appState.sites)
              .map((site) => {
                const alarms = Object.values(site.site.alarms);
                // const anyAlarmsActive = alarms.some((a) => a.status !== 'Over');
                const anyAlarmsUnhandled = alarms.some((a) => a.status === 'Unhandled');
                const anyFaults = Object.values(site.site.devices).some(
                  (d) => {
                    if (Object.keys(d.faults).length) {
                      return true;
                    }
                    if ((d.last_contact === undefined) || (d.last_contact === null)) {
                      return true;
                    }
                    const hoursSinceLastContact = -(DateTime.fromISO(d.last_contact, { zone: 'utc' }).diffNow().as('hours'));
                    if (hoursSinceLastContact >= 2) {
                      return true;
                    }
                    return false;
                  },
                );
                const servicesWithFault = Object.entries(site.site.services).filter(([, status_info]) => status_info.status_severity === 'Fault');
                const servicesWithCriticalFault = Object.entries(site.site.services).filter(([, status_info]) => status_info.status_severity === 'Critical Fault');
                // const allRunning =
                //   ((servicesWithFault.length + servicesWithCriticalFault.length) === 0);
                const haveFault = (servicesWithFault.length !== 0);
                const haveCriticalFault = (servicesWithCriticalFault.length !== 0);
                const [url, zIdx] = (
                  anyAlarmsUnhandled ? ['icons/pins/unhandled.png', 90]
                    // : anyAlarmsActive ? ['icons/pins/fire.png', 70]
                    : haveCriticalFault ? ['icons/pins/offline.png', 50]
                    : (anyFaults || haveFault) ? ['icons/pins/fault.png', 30]
                    : ['icons/pins/ok.png', 10]
                );
                const icon: google.maps.Icon = ({
                  url,
                  scaledSize: { height: 60, width: 60 },
                } as unknown as google.maps.Icon);
                return (
                  <InfoWindow
                    key={site.site.id}
                    marker={(
                      <Marker
                        title={site.site.name}
                        // optimized={false}
                        position={site.site.info?.coords}
                        animation={anyAlarmsUnhandled ? 1.0 : 2.0}
                        icon={icon}
                        zIndex={zIdx}
                      />
                    )}
                  >
                    <h3>{site.site.name}</h3>
                    <address>{site.site.info?.address}</address>
                    <NavLink
                      to={`/site/${site.site.id}`}
                      className="m-1 btn btn-icon btn-md btn-icon-right btn-purple-base text-nowrap"
                    >
                      View
                      <i className="bi-chevron-right" />
                    </NavLink>
                  </InfoWindow>
                );
              })}
          </GMap>
        </div>
      </Block>
      <Block heading="Site List">
        <Table
          editable={false}
          columns={{
            site: { node: 'Site', classes: 'fs-4 text-nowrap text-purple-dark pe-xl-3 col-12' },
            addr: { node: 'Address', classes: 'w-100 align-middle text-purple-base col-12' },
            // __spacer__: { node: null, classes: 'flex-grow' },
            online: { node: 'Online', classes: 'col-12 col-sm-6' },
            alarms: { node: 'Alarms', classes: 'col-12 col-sm-6' },
            faults: { node: 'Faults', classes: 'col-12 col-sm-6' },
            link: { node: 'Link', classes: 'col-12 col-sm-6' },
          }}
          data={Object.fromEntries(Object.values(appState.sites).map((site) => {
            const activeAlarms = Object.values(site.site.alarms).filter((a) => a.status !== 'Over');
            const unhandledAlarms = activeAlarms.filter((a) => a.status === 'Unhandled');
            const faultCount = {
              CRITICAL: 0, WARNING: 0, MILD: 0, UNKNOWN: 0,
            };
            Object.values(site.site.devices).forEach(
              (d) => {
                Object.values(d.faults).forEach((f) => { faultCount[f.severity] += 1; });
                if ((d.last_contact === undefined) || (d.last_contact === null)) {
                  faultCount.CRITICAL += 1;
                } else {
                  const hoursSinceLastContact = -(DateTime.fromISO(d.last_contact, { zone: 'utc' }).diffNow().as('hours'));
                  if (hoursSinceLastContact >= 5) {
                    faultCount.CRITICAL += 1;
                  } else if (hoursSinceLastContact >= 2) {
                    faultCount.WARNING += 1;
                  }
                }
              },
            );
            const totalFaults = Object.values(faultCount).reduce((prev, cur) => prev + cur, 0);
            const badgeClass = 'badge-xl text-center align-middle w-100';
            const servicesWithFault = Object.entries(site.site.services).filter(([, status_info]) => status_info.status_severity === 'Fault');
            const servicesWithCriticalFault = Object.entries(site.site.services).filter(([, status_info]) => status_info.status_severity === 'Critical Fault');
            const allRunning = (servicesWithFault.length + servicesWithCriticalFault.length) === 0;
            // const haveFault = (servicesWithFault.length !== 0);
            const haveCriticalFault = (servicesWithCriticalFault.length !== 0);
            const siteServiceStatus = (
              <Badge
                bg={haveCriticalFault ? 'danger' : (allRunning ? 'success' : 'warning')}
                className={badgeClass}
              >
                {/* <span className={
                  allRunning ? 'bi-play-circle-fill' : 'bi-exclamation-octagon-fill'} /> */}
                {haveCriticalFault ? 'Site Error!' : (allRunning ? 'Site Online' : 'Site Warning!')}
              </Badge>
            );
            return [
              site.site.id,
              {
                site: <strong>{site.site.name}</strong>,
                online: (
                  siteServiceStatus
                  // <Badge
                  //   bg={site.serviceError === null ? 'success' : 'danger'}
                  //   className={badgeClass}
                  // >
                  //   {site.serviceError === null ? 'Online' : 'Offline'}
                  // </Badge>
                ),
                alarms: (
                  unhandledAlarms.length > 0
                  ? <Badge bg="danger" className={badgeClass}>{`${unhandledAlarms.length} Unhandled Alarm(s)`}</Badge>
                  : activeAlarms.length > 0
                  ? <Badge bg="warning" className={badgeClass}>{`${activeAlarms.length} Active Alarm(s)`}</Badge>
                  : <Badge bg="success" className={badgeClass}>No Active Alarms</Badge>
                ),
                faults: (
                  <Badge
                    className={badgeClass}
                    bg={
                      (totalFaults === 0)
                      ? 'success'
                      : (((faultCount.CRITICAL + faultCount.UNKNOWN) !== 0) ? 'danger' : 'warning')
                    }
                  >
                    {`${totalFaults} Device Fault(s)`}
                  </Badge>
                ),
                addr: <address className="d-inline" style={{ minWidth: '10em' }}>{site.site.info?.address}</address>,
                link: (
                  <NavLink to={`/site/${site.site.id}`} className="d-flex w-100 mb-3 mb-xl-0">
                    <Button className="btn-icon d-flex text-nowrap w-100">
                      View&nbsp;
                      <i className="bi-chevron-right" />
                    </Button>
                  </NavLink>
                ),
              },
            ];
          }))}
        />
      </Block>
    </Page>
  );
}
