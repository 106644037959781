import * as React from 'react';

import {
  Button,
  /* Carousel, */
  Col,
  /* Row, */
} from 'react-bootstrap';

import {
  Navigate,
  NavLink,
  useParams,
} from 'react-router-dom';

import CameraDisplay from '../components/CameraDisplay';
import Page from '../components/Page';

import {
  Model,
} from '../model';

export default function CameraPage({
  appState,
  onRequestCamera,
}: {
  appState: Model,
  onRequestCamera: (siteId: string, camId: string) => any
}) {
  const { siteId, camId } = useParams();
  if ((siteId === undefined) || !(siteId in appState.sites)) {
    return <Navigate to="/404" />;
  }
  const site = appState.sites[siteId];

  if (camId === undefined || !(camId in site.site.cameras)) {
    return <Navigate to="/404" />;
  }
  const camera = site.site.cameras[camId];

  const homeButton = (
    <NavLink to={`/site/${site.site.id}#Cameras`} className="d-inline-flex text-nowrap p-5 pt-0">
      <Button className="btn-icon d-inline-flex text-nowrap">
        <i className="bi-chevron-left p-1" />
        Back to site
      </Button>
    </NavLink>
  );

  return (
    <Page heading={`Camera / ${camera.name} - ${camera.location}`} topElem={homeButton}>
      <Col xl={6}>
        <CameraDisplay
          url={camera.transcodedRtspStream || ''}
          width="100%"
          height="100%"
          displayStatus={camera.displayStatus}
          onRequestCamera={
            () => {
              onRequestCamera(siteId, camId);
            }
          }
        />
      </Col>
    </Page>
  );
}
