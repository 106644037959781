import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorMsg from './FallBack';

type PageProps = React.PropsWithChildren<{
  heading: string, topElem?: React.ReactNode,
}>;

export default function Page({ heading, children, topElem = null }: PageProps) {
  return (
    <Col
      as="main"
      className="p-1 p-lg-5 overflow-auto h-100 bg-white"
      data-bs-spy="scroll"
      data-bs-target="#page-nav"
    >
      <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
        <h1 className="mt-2 mb-3 mb-md-5 mx-4">{heading}</h1>
        {topElem}
      </div>
      <Container fluid>
        <Row className="gx-5">
          <ErrorBoundary FallbackComponent={ErrorMsg}>
            {children}
          </ErrorBoundary>
        </Row>
      </Container>
    </Col>
  );
}
