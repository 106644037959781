import {
  SiteAccess,
} from '../model';

export function canOperateSite(access: SiteAccess) {
  switch (access) {
  case 'operate':
  case 'administer':
    return true;
  default:
    return false;
  }
}

export function canManageSite(access: SiteAccess) {
  return (access === 'administer');
}
