import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorMsg from './FallBack';

type BlockProps = {
  heading: string | null,
  id?: string,
  _ref?: React.LegacyRef<HTMLDivElement>,
  bg?: string
  topElem?: React.ReactNode,
  children?: React.ReactNode,
  padding?: 0 | 1 | 2 | 3 | 4 | 5,
  headingMb?: 0 | 1 | 2 | 3 | 4 | 5,
};

export default function Block({
  heading, id, _ref, bg = 'bg-light', topElem = null, children = null, padding = 4, headingMb = 3,
}: BlockProps) {
  return (
    <div tabIndex={-1} id={id} ref={_ref} className={`${bg} p-${padding} mb-5 rounded-3 shadow block`}>
      <section className="flex-column h-100">
        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
          {heading === null ? null : <h2 className={`mb-${headingMb}`}>{ heading }</h2>}
          { topElem }
        </div>
        <ErrorBoundary FallbackComponent={ErrorMsg}>
          { children }
        </ErrorBoundary>
      </section>
    </div>
  );
}
