import * as React from 'react';
import {
  Badge, Button, Col, Form, Row,
} from 'react-bootstrap';
import { useParams, NavLink, Navigate } from 'react-router-dom';
import Block from '../components/Block';
import CallOnMount from '../components/CallOnMount';
import CameraDisplay from '../components/CameraDisplay';
import Page from '../components/Page';
import Time from '../components/Time';
import { canOperateSite } from '../utils/AccessUtils';

import { Model, AlarmDetermination, Device } from '../model';

type AlarmPageProps = {
  appState: Model,
  onRequestCamera: (siteId: string, cameraId: string) => any,
  onClassify: (siteId: string, alarmId: string, classification: AlarmDetermination) => any,
  onTakeOwnership: (siteId: string, alarmId: string) => any,
  // onEndAlarm: (alarmId: string) => any,
};

type ClassifyAlarmProps = {
  siteId: string,
  alarmId: string,
  onClassify: AlarmPageProps['onClassify'],
  onTakeOwnership: AlarmPageProps['onTakeOwnership'],
};

// type EndAlarmProps = {
//   alarmId: string,
//   onEndAlarm: AlarmPageProps['onEndAlarm']
// };

function ClassifyAlarm(
  {
    siteId,
    alarmId,
    onClassify,
    onTakeOwnership,
  }: ClassifyAlarmProps,
) {
  const [classification, setClassification] = React.useState<AlarmDetermination | null>(null);
  return (
    <Form className="flex-column h-100 pb-5 pb-xl-3">
      <fieldset>
        <legend>
          <h4>Classify the Alarm</h4>
          <small className="fs-6">
            Use the camera(s) below to determine whether the alarm is a false alarm or not.
            Click video for full-screen display.
          </small>
        </legend>
        <hr />
        <div className="d-flex flex-column">
          <CallOnMount callback={onTakeOwnership(siteId, alarmId)} />
          <Row className="justify-content-evenly">
            <Col xs="auto" className="py-2">
              <Form.Check
                inline
                name="classification"
                type="radio"
                label={<strong className="fs-4 fw-bold">False Alarm</strong>}
                // disabled={handled}
                onClick={() => setClassification('False Alarm')}
              />
            </Col>
            <Col xs="auto" className="py-2">
              <Form.Check
                inline
                name="classification"
                type="radio"
                label={<strong className="fs-4 fw-bold">Real Fire</strong>}
                // disabled={handled}
                onClick={() => setClassification('Fire')}
              />
            </Col>
          </Row>
          <Col xs={12}>
            <hr className="m-2" />
            <Button
              disabled={classification === null}
              className="mt-2 w-100 text-center"
              onClick={() => onClassify(siteId, alarmId, classification as AlarmDetermination)}
            >
              Submit
            </Button>
          </Col>
        </div>
      </fieldset>
    </Form>
  );
}

// function EndAlarm({ alarmId, onEndAlarm }: EndAlarmProps) {
//   return (
//     <>
//       <h4>End the alarm</h4>
//       <p>All new devices in this zone that detect a fire are added to this alarm.</p>
//       <p>When this fire is over, mark the alarm as complete with the button below.</p>
//       <p>Any devices that detect a fire in this zone will then raise a new alarm.</p>
//       <Button className="mt-2 w-100 text-center"
//          onClick={() => onEndAlarm(alarmId)}>End Alarm</Button>
//     </>
//   );
// }

export default function AlarmPage({
  appState,
  onRequestCamera,
  onClassify,
  onTakeOwnership,
}: AlarmPageProps) {
  const { siteId, alarmId } = useParams();

  if (siteId === undefined || !(siteId in appState.sites)) {
    return <Navigate to="/404" />;
  }
  const site = appState.sites[siteId];

  if (alarmId === undefined || !(alarmId in site.site.alarms)) {
    return <Navigate to="/404" />;
  }
  const alarm = site.site.alarms[alarmId];
  const devices: [string, Device][] = alarm.device_ids.map(
    (devId) => [devId, site.site.devices[devId]],
  );
  const cameras = Object.values(site.site.cameras)
    .filter((cam) => cam.dev_ids.some(
      (devId) => alarm.device_ids.includes(devId),
    ));

  const statusBadge = (
    <Badge pill bg={{ Unhandled: 'danger', Active: 'warning', Over: 'secondary' }[alarm.status]} className="mb-3 badge-xl">
      {alarm.status === 'Over' ? 'Handled' : 'Unhandled'}
    </Badge>
  );

  const classifyBadge = (
      alarm.determination === null
      ? <Badge pill bg="danger" className="mb-3 badge-xl">Unclassified</Badge>
      : alarm.determination === 'False Alarm'
      ? <Badge pill bg="success" className="mb-3 badge-xl">False Alarm</Badge>
      : <Badge pill bg="warning" className="mb-3 badge-xl">Fire</Badge>
  );

  const homeButton = (
    <NavLink to="/" className="d-inline-flex text-nowrap p-5 pt-0">
      <Button className="btn-icon d-inline-flex text-nowrap">
        <i className="bi-chevron-left p-1" />
        Back to home
      </Button>
    </NavLink>
  );

  return (
    <Page heading="Fire Alarm" topElem={homeButton}>
      <Col xl={6} className="d-grid">
        <Block heading="Info" topElem={statusBadge}>
          <Row as="dl">
            <Col xs={12} xl={4}>
              <dt>Site</dt>
              <dd><NavLink to={`/site/${siteId}`}>{site.site.name}</NavLink></dd>
              <dt>Zone</dt>
              <dd>{site?.site.zones[alarm?.zone_id]?.name}</dd>
              <dt>Time</dt>
              <dd><Time date={alarm.raised_at} /></dd>
            </Col>
            <Col xs={12} xl={8}>
              <dt>Devices</dt>
              <dd>
                <ul>
                  {
                    devices.map(([devId, device]) => (
                      device === undefined
                      ?  <li>{`Unknown device: ${devId}`}</li>
                      : (
                        <li key={device.id} className="p-1">
                          <Row><NavLink to={`/site/${siteId}/device/${device.id}`}>{device.name}</NavLink></Row>
                          <Row><small>{device.location}</small></Row>
                        </li>
                      )
                    ))
                  }
                </ul>
              </dd>
            </Col>
          </Row>
        </Block>
      </Col>
      <Col xl={6} className="d-grid">
        <Block heading="Action" topElem={classifyBadge}>
          {
            (alarm.status === 'Unhandled')
            ? (
              canOperateSite(site.access)
              ? (
                <ClassifyAlarm
                  siteId={siteId}
                  alarmId={alarm.id}
                  onClassify={onClassify}
                  onTakeOwnership={onTakeOwnership}
                />
                )
              : <p>The alarm is NOT handled.</p>
            )
            // : alarm.status === 'Active'
            // ? <EndAlarm alarmId={alarm.id} onEndAlarm={onEndAlarm} />
            : <p>The alarm is handled.</p>
          }
        </Block>
      </Col>
      {
        alarm.status === 'Unhandled'
        && cameras.map((cam) => (
          <Col xl={6} key={cam.id}>
            <Block heading={`Camera: ${cam.name} - ${cam.location}`}>
              <CameraDisplay
                url={cam.transcodedRtspStream || ''}
                width="100%"
                height="100%"
                displayStatus={cam.displayStatus}
                onRequestCamera={
                  () => {
                    onRequestCamera(siteId, cam.id);
                  }
                }
              />
            </Block>
          </Col>
        ))
      }
    </Page>
  );
}
