import {
  ResourceStatus,
} from '../model';

export function CameraButtonClass( // eslint-disable-line import/prefer-default-export
  displayStatus?: ResourceStatus,
) {
  switch (displayStatus) {
  case 'off':
    return '';
  case 'connecting':
    return 'btn-warning';
  case 'on':
    return 'btn-success';
  default: // unknown, error
    return 'btn-error';
  }
}
