import * as React from 'react';

interface Props {
  callback: () => any,
}

/**
 * A class that calls a function when the component gets mounted.
 */
export default class CallOnMount extends React.Component<Props> {
  componentDidMount() {
    const { callback } = this.props;
    if (callback) {
      callback();
    }
  }

  render() {
    return (
      <div />
    );
  }
}
