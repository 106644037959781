import * as React from 'react';
import ReactPlayer from 'react-player';
import TimedCaller from './TimedCaller';
import { ResourceStatus } from '../model';

type CameraDisplay2Props = React.PropsWithChildren<{
  url: string,
  width?: string,
  height?: string,
}>;

interface CameraDisplay2State {
  playing: boolean;
  playUrl: string;
}

class Player extends React.Component<CameraDisplay2Props, CameraDisplay2State> {
  private parentDiv: HTMLDivElement | null = null;

  constructor(props: CameraDisplay2Props) {
    super(props);
    this.state = {
      playing: false,
      playUrl: '',
    };
  }

  componentDidMount() {
    const { url } = this.props;
    this.setState({
      playing: false,
      playUrl: '',
    });
    this.checkForUrlLive(url);
  }

  componentDidUpdate(prevProps: CameraDisplay2Props) {
    const { url } = this.props;

    if (url !== prevProps.url) {
      this.setState({
        playing: false,
        playUrl: '',
      });
      this.checkForUrlLive(url);
    }
  }

  onPlayerClick() {
    if (this.parentDiv) {
      if (this.parentDiv.requestFullscreen) {
        this.parentDiv.requestFullscreen();
      }
    }
  }

  isCurrentUrl(checkUrl: string) {
    const { url } = this.props;
    return (checkUrl === url);
  }

  // For ReactPlayer to work reliably, the stream needs to exist before the
  // player tries to play it. To ensure that, we check that the stream url
  // is live before providing the url to the player.
  checkForUrlLive(url: string) {
    if (url) {
      fetch(url).then((response) => {
        if (response.ok && this.isCurrentUrl(url)) {
          this.setState({
            playing: false,
            playUrl: '',
          });
          this.setState({
            playing: true,
            playUrl: url,
          });
        }
      });
    }
  }

  render() {
    const { width, height } = this.props;
    const { playing, playUrl } = this.state;

    return (
      <div
        ref={(p) => {
          this.parentDiv = p;
        }}
      >
        <ReactPlayer
          url={playUrl}
          width={width}
          height={height}
          playing={playing}
          // Used this form so 'this' will work in callback:
          onClick={() => { this.onPlayerClick(); }}
          volume={0}
          muted
          alt="Unable to connect to camera."
          config={{
            file: {
              forceHLS: true,
              hlsOptions: {
                backBufferLength: 0, // Only keep minimum amount of played video.
                maxBufferLength: 5, // seconds
                maxMaxBufferLength: 10, // seconds
                maxBufferSize: 10, // MB
                progressive: true,
                lowLatencyMode: true,
                liveMaxLatencyDuration: 6, // seconds
                liveSyncDuration: 3, // seconds
                liveDurationInfinity: true,
                fragLoadingTimeOut: 3000,
                startFragPrefetch: true,
                fetchSetup(context: any, initParams: any) {
                  // Allow accessing http stream from https page.
                  const newInitParams: RequestInit = initParams;
                  newInitParams.referrerPolicy = 'unsafe-url';
                  return new Request(context.url, newInitParams);
                },
              },
            },
          }}
        />
      </div>
    );
  }
}

type CameraDisplayProps = React.PropsWithChildren<{
  url: string,
  width?: string,
  height?: string,
  displayStatus?: ResourceStatus,
  onRequestCamera: () => any,
}>;

export default function CameraDisplay({
  url,
  width,
  height,
  displayStatus,
  onRequestCamera,
}: CameraDisplayProps) {
  return (
    <div>
      <TimedCaller timeSecs={10} callback={onRequestCamera} />
      {
        ((displayStatus === 'on') && url && (
          <div>
            <h4>Click video for full-screen display.</h4>
            <Player
              url={url}
              width={width}
              height={height}
            />
          </div>
        )) || (
          <h4>{`Camera status: ${displayStatus}`}</h4>
        )
      }
    </div>
  );
}
