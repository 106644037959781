import * as React from 'react';
import { Badge } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { Device } from '../model';

function DeviceBadgeCommon({
  content, bg, title, prefix,
}: { content: string, bg: string, title: string, prefix?: string }) {
  // const badgeClassName = 'badge-xl d-flex me-4 mt-2 mb-3 mb-md-5 mx-4';
  const badgeClassName = 'badge-xl d-inline w-100';
  return (
    <Badge
      bg={bg}
      className={badgeClassName}
      title={title}
    >
      {prefix ? `${prefix} ` : null}
      {content}
    </Badge>
  );
}

export default function DeviceStatusBadge({ device, prefix = 'Device' }: { device: Device, prefix?: string }) {
  // return (
  //   <DeviceBadgeCommon
  //     content="Online"
  //     bg="success"
  //     title="The device is active and sending regular heartbeats."
  //     prefix={prefix}
  //   />
  // );

  // if (!device.active) {
  //   return (
  //     <DeviceBadgeCommon
  //       content="Inactive"
  //       bg="warning"
  //       prefix={prefix}
  //       title="The device is not part of the network and will not emit alarms."
  //     />
  //   );
  // }

  // try {
  //   if (device.health === undefined) {
  //     throw Error('No device health information.');
  //   }

  //   if (device.health === 0) {
  //     return (
  //       <DeviceBadgeCommon
  //         content="Offline"
  //         bg="danger"
  //         title="The device has not been heard from in a while."
  //         prefix={prefix}
  //       />
  //     );
  //   }

  //   if (device.health < 100) {
  //     return (
  //       <DeviceBadgeCommon
  //         content="Missing HB's"
  //         bg="warning"
  //         title={`The device has missed some heartbeats. Health: ${device.health}%.`}
  //         prefix={prefix}
  //       />
  //     );
  //   }

  //   if (device.health === 100 && device.active) {
  //     return (
  //       <DeviceBadgeCommon
  //         content="Online"
  //         bg="success"
  //         title="The device is active and sending regular heartbeats."
  //         prefix={prefix}
  //       />
  //     );
  //   }
  //   throw Error('Should not reach. Unexpected state.');
  // } catch (error) {
  //   console.error('Unexpected device state.', device);
  //   return (
  //     <DeviceBadgeCommon
  //       content="In Unknown State"
  //       bg="danger"
  //       title="The device state is invalid."
  //       prefix={prefix}
  //     />
  //   );
  // }

  if (device.last_contact === undefined || device.last_contact === null) {
    return (
      <DeviceBadgeCommon
        content="Unknown"
        bg="danger"
        title="Device last contact unknown"
        prefix={prefix}
      />
    );
  }

  const hoursSinceLastContact = -(DateTime.fromISO(device.last_contact, { zone: 'utc' }).diffNow().as('hours'));

  if (hoursSinceLastContact < 2) {
    return (
      <DeviceBadgeCommon
        content="Online"
        bg="success"
        title={`Device has been heard from in the last two hours. Hours since contact: ${hoursSinceLastContact}`}
        prefix={prefix}
      />
    );
  } else if (hoursSinceLastContact < 5) {  // eslint-disable-line no-else-return
    return (
      <DeviceBadgeCommon
        content="Recently Missing"
        bg="warning"
        title="The device hasn't been heard from in a while."
        prefix={prefix}
      />
    );
  } else {
    return (
      <DeviceBadgeCommon
        content="Offline"
        bg="danger"
        title="The device hasn't been heard from in over five hours."
        prefix={prefix}
      />
    );
  }
}
