import * as React from 'react';
import { FallbackProps } from 'react-error-boundary';

export default function ErrorMsg({ error }: FallbackProps) {
  console.error(error);
  return (
    <div className="text-danger">
      <p>Something went wrong.</p>
      {/* <pre>{error.message}</pre> */}
    </div>
  );
}
