import * as React from 'react';
import { DateTime } from 'luxon';
// import { DateISOStr } from '../model';

export default function Time(
  { date, relative = false, seconds = false }:
  { date: Date | string, relative?: boolean, seconds?: boolean },
) {
  const dateObj = typeof date === 'string' ? DateTime.fromISO(date, { zone: 'utc' }) : DateTime.fromJSDate(date);

  // Use this because relative state will change. E.g. seconds will increment.
  const [state, setState] = React.useState(relative
    ? dateObj.toRelative()
    : dateObj.toLocal().toLocaleString({
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      second: seconds ? '2-digit' : undefined,
      timeZoneName: 'short',
    }));

  // We need to rerender periodically to avoid times like
  // '5 seconds ago' staying there until refresh.
  React.useEffect(() => {
    if (relative) {
      const interval = setInterval(() => {
        setState(dateObj.toRelative());
      }, 250);  // TODO: if perf concern, make min relative time 1min, and change interval to 30 sec

      return () => clearInterval(interval);
    }
    return () => undefined;
  }, [dateObj, relative]);

  // The minWidth is only when time is relative, to stop reflow every second.
  return (
    <time
      dateTime={dateObj.toISO()}
      className={relative ? 'd-inline-block' : 'd-inline'}
      style={{ minWidth: relative ? '8em' : undefined }}
    >
      {state}
    </time>
  );
}
