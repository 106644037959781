// Style imports
import './scss/app.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'font-awesome/css/font-awesome.css';

import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  HashRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import { Manager, Socket } from 'socket.io-client';
import { ErrorBoundary } from 'react-error-boundary';

// import { applyPatch } from 'fast-json-patch';
// import * as canonicalize from 'canonicalize';
// import * as SHA256 from 'crypto-js/sha256';
// import { enc as CryptoJsEncoders } from 'crypto-js';

// https://immerjs.github.io/immer/example-setstate#useimmer
import { useImmer } from 'use-immer';
/* eslint no-param-reassign: [
  "error", {
    "props": true,
    "ignorePropertyModificationsFor": ["draft"]
  }]
*/ // When using useImmer, you're meant to modify the param. We'll name the param 'draft'.

import { Model } from './model';
import AlarmPage from './pages/Alarm';
import AlarmsPage from './pages/Alarms';
import DevicePage from './pages/Device';
import CameraPage from './pages/Camera';
import LoginPage from './pages/Login';
import SitePage from './pages/Site';
import SitesPage from './pages/Sites';
import ActiveAlarmsList from './components/ActiveAlarmsList';
import HsnAlert from './components/HsnAlert';
import Sidebar from './components/Sidebar';
import ErrorMsg from './components/FallBack';
import IMjConfig from './components/IMjConfig';
// import { ErrorBoundary } from 'react-error-boundary';
// import SiteEdit from './pages/SiteEdit';

// Seconds in one day.
// This would only mute for a day, HOWEVER
// the till_unmuted_flag also gets set below which means
// this time value is actually ignored.
const MUTE_FOREVER = 1440;

let lastAlarmOwned: string = '';

function logIn(user: string, pwd: string, appSocket: Socket) {
  if (appSocket.connected) {
    console.error('Attempted to connect to already connected socket.');
    appSocket.auth = {};  // eslint-disable-line no-param-reassign
    appSocket.disconnect();
  }
  appSocket.auth = { user, pwd };  // eslint-disable-line no-param-reassign
  appSocket.connect();
}

function logOut(appSocket: Socket) {
  appSocket.auth = {};  // eslint-disable-line no-param-reassign
  appSocket.disconnect();
}

function requestCamera(appSocket: Socket, site: string, camera: string) {
  console.log('Requesting camera. Site: ', site, ' Camera: ', camera);
  if (!appSocket.connected) {
    console.error('Cannot send camera request when socket disconnected!');
    return;
  }
  appSocket.emit('request_camera', site, camera);
}

function App(
  {
    _mj_config,
  }: {
    _mj_config: IMjConfig,
  },
) {
  // window.SHA256 = SHA256;
  // window.CryptoJsEncoders = CryptoJsEncoders;
  // window.jsp = applyPatch;
  // window.jcs = canonicalize;
  const [mjConfig, setMjConfig]       = useState<IMjConfig>(_mj_config);
  const [appState, setAppState]       = useImmer<Model>({ sites: {} });
  const [gMapsApiKey, setGMapsApiKey] = useState<string | null>(null);
  const [usersName, setUsersName]     = useState<string | null>(null);
  const [isAdmin, setIsAdmin]         = useState<boolean>(false);
  const [isSingleSiteUser, setIsSingleSiteUser] = useState<boolean>(false);
  const [loginError, setLoginError]   = useState<null | 'auth' | 'connection' | 'connecting' | 'loggedout'>(null);

  const [socketManager, setSocketManager] = useState<Manager>(
    new Manager(mjConfig.api_gateway_url, { autoConnect: false }),
  );
  const [appSocket, setAppSocket] = useState<Socket>(socketManager.socket('/'));

  // Set up socket events to update state using the above setX functions.
  React.useEffect(() => {
    appSocket
      .on('connect_error', (err) => {
        if (err.message.startsWith('Auth:')) {
          setLoginError('auth');
          console.log('invalid auth');
        } else {
          setLoginError('connection');
          console.error('socket connect error!', err);
        }
      })
      .on('connect',          ()               => { setLoginError(null); console.log('/ socket connected!'); })
      .on('disconnect',       (reason)         => {
        if (loginError !== 'loggedout') {
          setLoginError('connection');
        }
        console.log('socket disconnected!', reason);
      })
      .on('full_model',       (data)           => {
        setAppState(data);
        setIsSingleSiteUser(Object.values(data.sites).length === 1);
      })
      .on('site_model',       ({ site, data }) => { setAppState((draft) => { draft.sites[site].site = data; }); })
      .on('gmaps_api_key',    ({ key })        => { setGMapsApiKey(key); })
      .on('display_name',     ({ name, user_type })       => {
        setUsersName(name);
        setIsAdmin((user_type === 'administrator') || (user_type === 'superuser'));
      });
  }, [
    loginError,
    appState.sites,
    appSocket,
    setMjConfig,
    setAppState,
    setLoginError,
    setGMapsApiKey,
    setUsersName,
    setIsAdmin,
    setIsSingleSiteUser,
    setSocketManager,
    setAppSocket]);  // Providing set* even though they're stable: https://github.com/facebook/react/issues/20097

  const showMaintenanceNotification: Boolean = false;
  const notifyMessage: string = `
    Update in progress.
    Website functionality will be limited for some minutes until update is complete`;
  const maintenanceNotification = (
    showMaintenanceNotification
    ? (
      <HsnAlert
        header="Maintenance Message"
        theme={{ variant: 'CRITICAL' }}
        className="d-inline-block position-absolute zindex-modal translate-middle w-50 m-0 top-50 start-50"
      >
        {notifyMessage}
      </HsnAlert>
    )
    : null
  );

  if (usersName === null) {
    return (
      <Container fluid className="h-100">
        <LoginPage
          login={(user: string, pwd: string) => {
            setLoginError('connecting');
            logIn(user, pwd, appSocket);
          }}
          loginError={loginError}
        />
        {maintenanceNotification}
      </Container>
    );
  }

  const connError = (
    loginError === 'connection'
    ? (
      <HsnAlert
        header="Connection Lost"
        theme={{ variant: 'CRITICAL' }}
        className="d-inline-block position-absolute zindex-modal translate-middle w-50 m-0 top-50 start-50"
      >
        Attempting to reconnect.
      </HsnAlert>
    )
    : null
  );

  const homePage: string = (
    isSingleSiteUser ? `/site/${Object.values(appState.sites)[0].site.id}` : '/sites'
  );

  return (
    // Using a Hash Router so the site can be used as a SPA without special server configuration.
    <ErrorBoundary FallbackComponent={ErrorMsg}>
      <HashRouter>
        <div className="app-box">
          <div className="app-header-row">
            <ActiveAlarmsList appState={appState} />
          </div>
          <div className="fill-remaining-vertical">
            <Container fluid className="h-100">
              <Row id="mjAppBody" className="h-100">
                <Sidebar
                  user={usersName}
                  isAdmin={!!isAdmin}
                  isSingleSiteUser={!!isSingleSiteUser}
                  signOut={() => {
                    logOut(appSocket);
                    setLoginError('loggedout');
                    setAppState({ sites: {} });
                    setUsersName(null);
                    setIsAdmin(false);
                    setGMapsApiKey(null);
                  }}
                />
                <ErrorBoundary FallbackComponent={ErrorMsg}>
                  <Routes>
                    <Route path="/" element={<Navigate to={homePage} />} />
                    <Route path="/index.html" element={<Navigate to={homePage} />} />
                    <Route path="/Alarms" element={<AlarmsPage appState={appState} />} />
                    <Route
                      path="/site/:siteId/alarm/:alarmId"
                      element={(
                        <AlarmPage
                          appState={appState}
                          // onView={}  send take_ownership msg.
                          onRequestCamera={
                            (siteId, cameraId) => {
                              requestCamera(appSocket, siteId, cameraId);
                            }
                          }
                          onClassify={
                            (siteId, alarmId, classification) => {
                              console.log('Classifying alarm!', siteId, alarmId, classification);
                              if (!appSocket.connected) {
                                console.error('Cannot send alarm acknowledgement when socket disconnected!');
                                return;
                                // Show some kind of user focused error.
                              }
                              appSocket.emit('classify_alarm', siteId, {
                                alarms: [{
                                  alarm_id: alarmId,
                                  action: 'determination',
                                  determination: classification,
                                  operator: usersName,
                                  trigger_fire_alarm: !(classification === 'False Alarm'),
                                }],
                              });
                              console.log('Emitted alarm acknowledgement.');
                            }
                          }
                          onTakeOwnership={
                            (siteId, alarmId) => {
                              if (alarmId !== lastAlarmOwned) {
                                lastAlarmOwned = alarmId;
                                console.log('Taking alarm ownership!', siteId, alarmId);
                                if (!appSocket.connected) {
                                  console.error('Cannot take alarm ownership when socket disconnected!');
                                  return;
                                  // Show some kind of user focused error.
                                }
                                appSocket.emit('take_alarm_ownership', siteId, {
                                  alarms: [{
                                    alarm_id: alarmId,
                                    action: 'take_ownership',
                                    operator: usersName,
                                  }],
                                });
                                console.log('Emitted take alarm ownership message.');
                              }
                            }
                          }
                          // onEndAlarm={
                          //   (alarmId) => {}
                          // }
                        />
                      )}
                    />
                    <Route
                      path="/site/:siteId/device/:devId"
                      element={(
                        <DevicePage
                          appState={appState}
                          onDeviceMute={
                            (siteId, devId, mute) => {
                              console.log('Setting mute for device.', siteId, devId, mute);
                              if (!appSocket.connected) {
                                console.error('Cannot send device command when socket disconnected!');
                                return;
                                // Show some kind of user focused error.
                              }
                              appSocket.emit('send_device_command', siteId, {
                                name: 'Mute',
                                type: 'device_command',
                                devices: [devId],
                                params: {
                                  minutes_to_mute: mute ? MUTE_FOREVER : 0,
                                  till_unmuted_flag: mute ? 1 : 0,
                                },
                              });
                              console.log('Emitted device mute command.');
                            }
                          }
                          onReset={
                            (siteId, devId) => {
                              console.log('Sending reset command for device:', siteId, devId);
                              appSocket.emit('send_device_command', siteId, {
                                name: 'HardwareReset',
                                type: 'device_command',
                                devices: [devId],
                                params: {},
                              });
                              console.log('Emitted device reset command.');
                            }
                          }
                        />
                      )}
                    />
                    <Route
                      path="/site/:siteId/camera/:camId"
                      element={(
                        <CameraPage
                          appState={appState}
                          onRequestCamera={
                            (siteId, cameraId) => {
                              requestCamera(appSocket, siteId, cameraId);
                            }
                          }
                        />
                      )}
                    />
                    <Route path="/sites" element={<SitesPage appState={appState} gMapsApiKey={gMapsApiKey} />} />
                    <Route
                      path="/site/:siteId"
                      element={(
                        <SitePage
                          appState={appState}
                          gMapsApiKey={gMapsApiKey}
                          userName={usersName}
                          isSingleSiteUser={!!isSingleSiteUser}
                          onRowNew={
                            (site, table, data) => {
                              appSocket.emit('table_new_row', table, site, data);
                            }
                          }
                          onRowUpdate={
                            (site, table, key, data) => {
                              appSocket.emit('table_edit_row', table, site, key, data);
                            }
                          }
                          onRowDel={
                            (site, table, key) => {
                              appSocket.emit('table_del_row', table, site, key);
                            }
                          }
                        />
                      )}
                    />
                    <Route
                      path="/404"
                      element={(
                        <Col className="text-center align-self-center">
                          <h1 style={{ fontSize: '150px' }}>404</h1>
                          <h2>Not Found</h2>
                          <p>The resource requested could not be found!</p>
                        </Col>
                      )}
                    />
                    <Route path="*" element={<Navigate to="/404" />} />
                  </Routes>
                </ErrorBoundary>
              </Row>
              {connError}
              {maintenanceNotification}
            </Container>
          </div>
        </div>
      </HashRouter>
    </ErrorBoundary>
  );
}

export default App;
