import * as React from 'react';
import { Badge, Button } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { NavLink } from 'react-router-dom';

import Block from '../components/Block';
import Page from '../components/Page';
import Table from '../components/Table';
import Time from '../components/Time';
import ErrorMsg from '../components/FallBack';
import { Model } from '../model';
import { sortByStrValue } from '../util';

export default function AlarmsPage({ appState }: { appState: Model }) {
  const badgeClass = 'badge-xl text-center align-middle w-100';
  const allAlarms = sortByStrValue(
    'raised_at',
    Object.values(appState.sites)
      .flatMap((site) => (
        Object.values(site.site.alarms)
          .map((alarm) => ({ site_id: site.site.id, alarm, raised_at: alarm.raised_at }))
      )),
    true,
  );

  const homeButton = (
    <NavLink to="/" className="d-inline-flex text-nowrap p-5 pt-0">
      <Button className="btn-icon d-inline-flex text-nowrap">
        <i className="bi-chevron-left p-1" />
        Back to home
      </Button>
    </NavLink>
  );

  return (
    <Page heading="Alarms" topElem={homeButton}>
      <Block heading="Alarm List">
        <Table
          editable={false}
          columns={{
            site: { node: 'Site', classes: 'text-center text-xl-start text-nowrap' },
            dev: { node: 'Device', classes: 'text-center text-xl-start' },
            status: { node: 'Status', classes: 'text-nowrap' },
            class: { node: 'Classification', classes: 'text-nowrap' },
            __spacer__: { node: null, classes: 'w-100 me-auto d-none d-xl-table-cell' },
            link: { node: 'Link' },
          }}
          data={Object.fromEntries(allAlarms.map(({ site_id, alarm }) => {
            const site = appState.sites[site_id];
            return [
              alarm.id,
              {
                status: (
                  <Badge
                    bg={{ Unhandled: 'danger', Active: 'warning', Over: 'secondary' }[alarm.status]}
                    className={badgeClass}
                  >
                    {alarm.status === 'Over' ? 'Handled' : 'Unhandled'}
                  </Badge>
                ),
                site: (
                  <span>
                    <div className="fw-bold"><NavLink to={`/site/${site.site.id}`}>{site.site.name}</NavLink></div>
                    <Time date={alarm.raised_at} />
                  </span>
                ),
                dev: (
                  <ErrorBoundary FallbackComponent={ErrorMsg}>
                    <span>
                      <div className="fw-bold">{site.site.zones[alarm.zone_id].name}</div>
                      <div className="text-nowrap">
                        {/* {alarm.device_ids.length} */}
                        {/* &nbsp;device(s) in alarm */}
                        {/* There is one device per alarm again (for now) */}
                        {
                          site.site.devices[alarm.device_ids[0]]
                          ? 'Device: '
                          : 'Unknown device: '
                        }
                        {
                          site.site.devices[alarm.device_ids[0]]
                          ? (
                            <NavLink
                              to={`/site/${site.site.id}/device/${alarm.device_ids[0]}`}
                            >
                              {site.site.devices[alarm.device_ids[0]]?.name}
                            </NavLink>
                          )
                          : alarm.device_ids[0]
                        }
                      </div>
                    </span>
                  </ErrorBoundary>
                ),
                class: (
                  alarm.determination === null
                  ? (
                    <Badge
                      bg={alarm.status === 'Over' ? 'secondary' : 'danger'}
                      className={badgeClass}
                    >
                      Unclassified
                    </Badge>
                  )
                  : alarm.determination === 'False Alarm'
                  ? (
                    <Badge
                      // bg={alarm.status === 'Ended' ? 'secondary' : 'warning'}
                      bg="secondary"
                      className={badgeClass}
                    >
                      False alarm
                    </Badge>
                  )
                  : (
                    <Badge
                      bg={alarm.status === 'Over' ? 'secondary' : 'warning'}
                      className={badgeClass}
                    >
                      Fire
                    </Badge>
                  )
                ),
                link: (
                  <NavLink to={`/site/${site_id}/alarm/${alarm.id}`} className="d-flex w-100 mb-3 mb-xl-0">
                    <Button className="btn-icon d-flex text-nowrap w-100">
                      View&nbsp;
                      <i className="bi-chevron-right" />
                    </Button>
                  </NavLink>
                ),
              },
            ];
          }))}
        />
      </Block>
    </Page>
  );
}
