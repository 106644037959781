import * as React from 'react';
import { Col, Image } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

type NavLinkProps = {
  section: string,
  icon: string,
  display?: string,
  relative?: boolean,
  onClick?: React.ComponentProps<typeof NavLink>['onClick']
};

function IconNavLink({
  section, icon, display = section, relative = false, onClick,
}: NavLinkProps) {
  return (
    <NavLink
      to={relative ? { hash: section } : { pathname: `${section}` }}
      className="px-0 text-light py-lg-2 nav-link"
      style={{ textDecoration: 'None' }}
      onClick={onClick}
    >
      <div style={{ whiteSpace: 'nowrap' }}>
        <span className={`fs-2 ${icon}`} />
        <span className="fs-4 ms-3 d-none d-lg-inline">{ display }</span>
      </div>
    </NavLink>
  );
}

function NavLinks({ isAdmin, isSingleSiteUser, signOut }:
{
  isAdmin: boolean,
  isSingleSiteUser: boolean,
  signOut: () => any
}) {
  const location = useLocation();

  const navLinks = [];

  if (!isSingleSiteUser) {
    navLinks.push(<IconNavLink section="sites" display="Site Map" icon="bi-map" />);
  }
  navLinks.push(<IconNavLink section="alarms" display="Alarms" icon="bi-bell" />);
  // <IconNavLink section="Dashboard" icon="bi-columns-gap" />,
  // <IconNavLink section="Devices" icon="bi-clipboard2-pulse" />,
  // <IconNavLink section="Commands" icon="bi-terminal" />,
  // <IconNavLink section="Services" icon="bi-stoplights" />,
  // <IconNavLink section="Edit" icon="bi-pencil-square" />,

  const pageLinks = [];
  const sitePageRe = /^\/site\/[^/]*$/;  // Match url: "/site/siteId" but not "/site/siteId/subSection/subId"

  if (sitePageRe.test(location.pathname)) {
    pageLinks.push(
      <IconNavLink relative section="Zones" display="Status" icon="bi-clipboard2-pulse" />,
      <IconNavLink relative section="Info" display="Info" icon="bi-info-circle" />,
      <IconNavLink relative section="Layout" icon="bi-house-door" />,
      <IconNavLink relative section="Map" icon="bi-pin-map" />,
      <IconNavLink relative section="Contacts" icon="bi-person-lines-fill" />,
      <IconNavLink relative section="Occupancy" icon="bi-person-bounding-box" />,
      <IconNavLink relative section="Risks" icon="bi-exclamation-octagon" />,
      <IconNavLink relative section="Mobility" icon="fa fa-wheelchair" />,
      <IconNavLink relative section="Cameras" icon="bi-camera" />,
      <IconNavLink relative section="Devices" icon="bi-cpu" />,
    );
  }

  const ulClasses = 'nav flex-column px-lg-4 align-items-center align-items-lg-start mb-0';
  const anyPageLinks = pageLinks.length !== 0;

  return (
    <>
      <ul className={`${ulClasses} ${anyPageLinks ? '' : 'mb-lg-auto'}`}>
        {
          isAdmin && (
            <li className="nav-item">
              <a
                className="px-0 text-light py-lg-2 nav-link"
                href="https://cmsadmin.hsn.systems/admin/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'None' }}
              >
                <div style={{ whiteSpace: 'nowrap' }}>
                  <span className="fs-2 bi-wrench" />
                  <span className="fs-4 ms-3 d-none d-lg-inline">Site Admin</span>
                </div>
              </a>
            </li>
          )
        }
        {navLinks.map((nl) => <li className="nav-item" key={nl.props.section}>{nl}</li>)}
      </ul>
      {!anyPageLinks ? null : (
        <div className="mb-lg-auto">
          <hr />
          <ul className={ulClasses} id="page-nav">
            {pageLinks.map((nl) => <li className="nav-item" key={nl.props.section}>{nl}</li>)}
          </ul>
        </div>
      )}
      <div>
        <hr />
        <ul className={ulClasses}>
          <li className="nav-item">
            <IconNavLink section="/" display="Sign out" icon="bi-box-arrow-left" onClick={signOut} />
          </li>
        </ul>
      </div>
    </>
  );
}

export default function Sidebar({
  user, isAdmin, isSingleSiteUser, signOut,
}:
{
  user: string,
  isAdmin: boolean,
  isSingleSiteUser: boolean,
  signOut: () => any
}) {
  return (
    <Col
      xs={1}
      lg={3}
      xl={2}
      className="bg-primary d-flex flex-column text-light px-1 px-lg-3 py-3 h-100 overflow-auto"
    >
      <a href="https://flamesecurityinternational.com/" target="_blank" rel="noreferrer">
        <Image src="/FSI-Logo-reversed.png" alt="FSI logo" className="img-fluid d-none d-lg-inline p-3" />
        <Image
          style={{ height: '50px' }}
          src="/FSI-Logo-Icon.png"
          alt="FSI logo"
          className="img-fluid m-auto d-block d-lg-none"
        />
      </a>

      {/* <hr /> */}
      <div className="d-none d-lg-block small p-4">
        Welcome
        <br />
        <strong className="fs-5 d-none d-lg-inline small">{user}</strong>
      </div>
      {/* <hr /> */}
      <NavLinks
        isAdmin={isAdmin}
        isSingleSiteUser={isSingleSiteUser}
        signOut={signOut}
      />
    </Col>
  );
}
