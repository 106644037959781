import * as React from 'react';
import { Alert } from 'react-bootstrap';
// import { DateISOStr } from '../model';
import Time from './Time';

const alertVariantMap = {
  CRITICAL: {
    icon: 'bi-exclamation-octagon',
    color: 'red-base',
  },
  WARNING: {
    icon: 'bi-exclamation-triangle',
    color: 'yellow-base',
  },
  MILD: {
    icon: 'bi-info-circle',
    color: 'blue-base',
  },
  UNKNOWN: {
    icon: 'bi-patch-question',
    color: 'black',
  },
} as const;

type AlertVariant =  keyof typeof alertVariantMap; // 'CRITICAL' | 'WARNING' | 'MILD' | 'UNKNOWN';
type HsnAlertProps = React.PropsWithChildren<{
  header: string,
  theme: (
    { variant: AlertVariant, color?: string, icon?: string }
    | { color: string, icon: string }),
  time?: string,  // DateISOStr,
  className?: string,
}>;

export default function HsnAlert({
  header, children, theme, time, className = '',
}: HsnAlertProps) {
  const icon = theme.icon !== undefined ? theme.icon
    : alertVariantMap[((theme as any).variant as AlertVariant)].icon;
  const color = theme.color !== undefined ? theme.color
    : alertVariantMap[((theme as any).variant as AlertVariant)].color;

  return (
    <Alert className={`d-flex flex-grow-1 shadow alert-${color} ${className}`}>
      <i className={`${icon} fs-1 me-3 as-alert-color d-flex flex-column justify-content-center`} />
      <div className="w-100 d-flex flex-column justify-content-center">
        <div className="d-flex justify-content-between">
          <strong className="text-uppercase fs-4">{header}</strong>
          { time ? <small><Time date={time} /></small> : null }
        </div>
        <br />
        {children}
      </div>
    </Alert>
  );
}
