import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Alarm, SiteAndAccess, Model } from '../model';
import HsnAlert from './HsnAlert';
import { canOperateSite } from '../utils/AccessUtils';

function ActiveAlarmItem({ xs, alarm, site }: { xs?: number, alarm: Alarm, site: SiteAndAccess }) {
  if (site === undefined) {
    return null;
  }

  return (
    <Col xs={xs}>
      <HsnAlert
        header="FIRE ALARM"
        time={alarm.raised_at}
        theme={{ variant: 'CRITICAL', icon: 'bi-alarm' }}
        className="mb-2 py-2 px-2 mx-2"
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <small className="fw-bold text-uppercase as-alert-color">Awaiting Classification&nbsp;</small>
                {/* <i className="bi bi-exclamation-circle fs-4" /> */}
              </div>
            </div>
            <div className="d-flex text-purple-dark">
              <span>
                {site !== undefined ? (
                  <strong>
                    <NavLink to={`/site/${site?.site?.id}`}>{site?.site?.name}</NavLink>
                    :&nbsp;
                  </strong>
                  ) : <strong>Unknown site</strong>}
                {site?.site?.zones[alarm?.zone_id]?.name}
              </span>
            </div>
          </div>
          {site !== undefined && (
            <div className="d-flex flex-column align-items-end mt-auto">
              <NavLink to={`/site/${site?.site?.id}/alarm/${alarm?.id}`} className="btn btn-icon btn-md btn-icon-right btn-purple-base text-nowrap">
                {canOperateSite(site.access) ? 'Classify' : 'View'}
                <i className="bi-chevron-right" />
              </NavLink>
            </div>
          )}
        </div>
      </HsnAlert>
    </Col>
  );
}

// ActiveAlarmItems -> Notifications.
// Unhandled Alarms are determined from the appState Alarms
export default function ActiveAlarmsList({ appState }: { appState: Model }) {
  // if (appState.user === null) return null;
  const activeAlarms = Object.values(appState.sites)
    .flatMap((site) => (Object.values(site.site.alarms).map((alarm) => ({ site, alarm }))))
    .filter(({ alarm }) => alarm.status === 'Unhandled');
  return (
    activeAlarms.length === 0 ? null
      : (
        <Row className="active-alarms-list bg-white">
          {
            activeAlarms.map(({ site, alarm }) => <ActiveAlarmItem xs={3} key={`${site.site.id}:${alarm.id}`} alarm={alarm} site={site} />)
          }
        </Row>
      )
  );
}
